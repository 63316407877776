import React from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/ToothLensLogo.png";
import Button from "@mui/material/Button";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createNewPasswordService } from "../../Services/AlignerService";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container fluid className="">
      {
        <Formik
          validate={(values) => {
            let errors = {};

            if (!values.new_password) {
              errors.new_password = "New Password is required";
            }
            if (!values.confirm_password) {
              errors.confirm_password = "Confirm Password is required";
            }
            if (values.new_password != values.confirm_password) {
              errors.confirm_password = "Password do not match";
            }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            new_password: Yup.string().required("Password is required"),
            confirm_password: Yup.string().required(
              "Confirm Password is required"
            ),
          })}
          initialValues={{
            new_password: "",
            confirm_password: "",
          }}
          onSubmit={(values) => {
            const data = {
              password: values.new_password,
              email: location.state.email,
              forgotpass_code: location.state.forgotpass_code,
            };
            createNewPasswordService(data)
              .then((response) => {
                if (response) {
                  navigate("/login");
                }
              })
              .catch((error) => {
                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex flex-column signup_image justify-content-center align-items-center"
                  >
                    <img
                      className="image-position"
                      src={ToothScan}
                      alt="Scan"
                    />
                    <div className="dashboard-footer d-flex  my-5 align-items-end height-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex justify-content-center align-items-center position"
                  >
                    <div className="position-absolute">
                      <img src={Logo} className="images" alt="Logo" />
                    </div>

                    <div>
                      <Col md={12}>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark font-style mx-3"
                        >
                          <span className="field-texts">
                            Create New Password
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          id="new_password"
                          className="form-input "
                          placeholder="xxxx"
                          type="text"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_password && touched.new_password && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.new_password}
                          </div>
                        )}
                      </Col>
                      <Col md={12}>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark font-style my-2 mt-4 mx-3"
                        >
                          <span className="field-texts">
                            Confirm New Password
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-4">
                        {" "}
                        <input
                          id="confirm_password"
                          className="form-input"
                          placeholder="xxxx"
                          type="text"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_password &&
                          touched.confirm_password && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.confirm_password}
                            </div>
                          )}
                      </Col>

                      <Col className="mt-4">
                        <Button className="form-btn" type="submit">
                          Create
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default NewPassword;
