import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Button from "@mui/material/Button";
import close from "../../assets/Close.svg";
import { FaUserMd } from "react-icons/fa";
import { Formik, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  dentistDetailsBasedOnId,
  updateDentistDetails,
  branchDetailsBasedOnCompany,
  roleList,
  allLocationsBasedOnCompany,
} from "../../Services/AlignerService";
import { Link, useNavigate } from "react-router-dom";
import SnackFire from "../../UI/Snackbar/Snackbar";
import TokenExpired from "../TokenExpired";
import { getDoctors } from "../../Store/doctors";

const DentistUpdatePopup = ({ popupstatus, dentistId, change }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const app = JSON.parse(localStorage.getItem("xld")).app;
  const [loading, setloading] = useState(true);
  const [roles, setroles] = useState("");
  const [chiefDoctor, setChiefDoctor] = useState();
  const [dentistDetails, setDentistDetails] = useState({});
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const handleclose = () => {
    setsnackbar(false);
  };
  const call = async () => {
    await dentistDetailsBasedOnId({ dentist_id: dentistId })
      .then((res) => {
        if (res.status === 200) {
          setDentistDetails(res.data.data.dentistDetails[0]);
          setChiefDoctor(res.data.data.dentistDetails[0].is_chief_doctor);
        }
      })
      .catch((err) => {
        return err;
      });
    // await allLocationsBasedOnCompany({ app })
    //   .then((res) => {
    //     setbranches(res.data.data);
    //   })
    //   .catch((err) => navigate("/"));


    await roleList()
      .then((res) => {
        setroles(res.data.data);
        setloading(false);
      })
      .catch((err) => {
        navigate("/");
      });
  };
  useEffect(() => {
    call();
  }, []);

  if (!loading) {
    return (
      <Formik
        validate={(values) => {
          let errors = {};

          if (!values.name) {
            errors.name = " Name is required";
          }

          if (!values.email) {
            errors.email = "Email is required";
          }

          return errors;
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(" Name is required"),
          email: Yup.string().email().required(" Email is Required"),
        })}
        initialValues={{
          name: dentistDetails.name ? dentistDetails.name : "",
          email: dentistDetails.email ? dentistDetails.email : "",
          phone_number: dentistDetails.phone_number
            ? dentistDetails.phone_number
            : "",
          role: dentistDetails.role ? dentistDetails.role : "",
          designation: dentistDetails.designation
            ? dentistDetails.designation
            : "",
        }}
        onSubmit={(values) => {
          const object = { chiefDoctor, ...values };
          updateDentistDetails({
            dentist_id: dentistDetails._id,
            ...object,
          })
            .then((res) => {
              setsnackbarmessage(res.data.message);
              JSON.stringify(res.data.data) === "{}"
                ? settype("error")
                : settype("success");
              setsnackbar(true);
              dispatch(getDoctors({ app, doctorRole: "63d93e90dae984f675862ad4" }))
              if (JSON.stringify(res.data.data) != "{}") {
                change();
              }
            })
            .catch((err) => {
              settype("error")
              setsnackbarmessage("something went wrong updating dentist details");
              setsnackbar(true);
            });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <>
              {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <div className="model-user">
                  <div className="model_container-user">
                    <div className="close_position">
                      <Button
                        onClick={() => {
                          // call();
                          popupstatus();
                        }}
                      >
                        {" "}
                        <img src={close} alt="close" width="50px" height="50px"></img>
                      </Button>
                    </div>

                    <div>
                      <div className="d-flex navbar_text_dashbord justify-content-center mt-5">
                        <div>
                          <FaUserMd
                            size="22px"
                            color="#0090FF"
                            className="mx-2"
                          />
                        </div>{" "}
                        <div>Dentist Details</div>
                      </div>
                      <div>
                        {" "}
                        <Col
                          md={12}
                          className="d-flex justify-content-center  mt-3"
                        >
                          <Col
                            md={5}
                            xs={12}
                            className="d-flex align-items-center justify-content-center position my-3 mx-3 mt-3"
                          >
                            <div>
                              <div className="d-flex w-5">
                                <Col md={6} className="mt-3">
                                  <FormLabel
                                    component="legend"
                                    className="mt-1 font-weight-500 text-dark field-text"
                                  >
                                    <span className="field-texts  mx-3">
                                      Dentist Name*
                                    </span>
                                  </FormLabel>
                                </Col>
                              </div>

                              <Col>
                                <div className="d-flex w-5 ">
                                  <Col md={3} className="mt-1 px-2 ">
                                    <input
                                      type="text"
                                      id="name"
                                      className="form-input mb-3 grey-bg-color"
                                      placeholder="Dr."
                                    />
                                  </Col>
                                  <Col md={9} className="mt-1 px-2">
                                    <input
                                      type="text"
                                      id="name"
                                      className="form-input mb-3 grey-bg-color"
                                      placeholder="name"
                                      value={values.name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>
                                </div>
                                <div className="mx-3">
                                  {" "}
                                  {errors.name && touched.name && (
                                    <div className="text-danger mb-2 ml-2">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col>
                                <FormLabel
                                  component="legend"
                                  className="pt-2 font-weight-500 text-dark field-text"
                                >
                                  <span className="field-texts  mx-3">
                                    Email*
                                  </span>
                                </FormLabel>
                              </Col>

                              <Col className="mt-1">
                                {" "}
                                <input
                                  type="text"
                                  id="email"
                                  className="form-input mb-3 grey-bg-color"
                                  placeholder="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <div className="mx-3">
                                {" "}
                                {errors.email && touched.email && (
                                  <div className="text-danger mb-2 ml-2">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <Col>
                                <FormLabel
                                  component="legend"
                                  className="pt-2 font-weight-500 text-dark field-text"
                                >
                                  <span className="field-texts  mx-3">
                                    Phone Number
                                  </span>
                                </FormLabel>
                              </Col>

                              <Col className="mt-1">
                                {" "}
                                <input
                                  type="number"
                                  id="phone_number"
                                  className="form-input mb-3 grey-bg-color"
                                  placeholder="phone_number"
                                  value={values.phone_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </div>

                            {/* </div> */}
                          </Col>

                          <Col
                            md={5}
                            xs={12}
                            className="d-flex align-items-center justify-content-center position my-3 mx-3 mt-3"
                          >
                            <div>
                              <div className="d-flex w-5">
                                <Col md={6} className="mt-3">
                                  <FormLabel
                                    component="legend"
                                    className="mt-1 font-weight-500 text-dark field-text"
                                  >
                                    <span className="field-texts  mx-3">
                                      Role
                                    </span>
                                  </FormLabel>
                                </Col>
                              </div>

                              <div className="d-flex w-5 ">
                                <Col md={12} className="mt-1 px-2 ">
                                  <select
                                    id="role"
                                    className="form-input mb-3 grey-bg-color"
                                    value={values.role}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: "block" }}
                                  >
                                    {roles.map((option) => {
                                      return (
                                        <option value={option._id} key={option._id}>
                                          {" "}
                                          {option.designation}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  <div className="mx-3">
                                    {" "}
                                    {errors.role && touched.role && (
                                      <div className="text-danger mb-2 ml-2">
                                        {errors.role}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </div>

                              <Col>
                                <FormLabel
                                  component="legend"
                                  className="pt-2 font-weight-500 text-dark field-text"
                                >
                                  <span className="field-texts  mx-3">
                                    Designation
                                  </span>
                                </FormLabel>
                              </Col>

                              <Col className="mt-1">
                                {" "}
                                <input
                                  type="text"
                                  id="designation"
                                  className="form-input mb-3 grey-bg-color"
                                  placeholder="designation"
                                  value={values.designation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </div>

                            {/* </div> */}
                          </Col>
                        </Col>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <Button className="form-btn" type="submit">
                          <span className="btn-text">Save and Update</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
};

export default DentistUpdatePopup;
