import PacmanLoader from "react-spinners/PacmanLoader";


const Loader = () => {

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;`;

    return (
        <div className="mt-5 mx-5 w-100">
            <div className=" d-flex align-items-center justify-content-center mt-5">
                <PacmanLoader color={"#0090FF"} css={override} size={150} />
                <span className="mt-3 navbar_text_dashbord">Loading...</span>
            </div>
        </div>
    )

}

export default Loader