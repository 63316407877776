import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "@mui/material/Button";
import { uploadVideoTos3Bucket, updateAlignerUserFlow } from '../../Services/AlignerService';
import SnackFire from '../../UI/Snackbar/Snackbar';
import { IoIosArrowBack } from "react-icons/io";
import TokenExpired from '../TokenExpired';

const RestartPlan = ({ back, alignerData, noOfReports, onClose, reload }) => {
    let { user_id } = useParams()
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Submit")
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const newRestart = { restartsAt: noOfReports, alignerNo: 1 }



    // const restartData = [
    //     {restartsAt : 3, alignerNo:5},
    //     {restartsAt : 8, alignerNo:0},
    // ]


    const updateRestartStages = (array, alignerNo = 1) => {
        const isExist = array.find(each => each.restartsAt == newRestart.restartsAt)
        let updatedArray
        if (!isExist) {
            updatedArray = [...array, newRestart].map((each) => {
                if (each.restartsAt == noOfReports) {
                    return { ...each, restartsAt: noOfReports, alignerNo }
                }
                return each
            })

        } else {
            updatedArray = array.map((each) => {
                if (each.restartsAt == noOfReports) {
                    return { ...each, restartsAt: noOfReports, alignerNo }
                }
                return each
            })

        }

        return updatedArray

    }


    const initialValues = {
        fileOption: "",
        file: "",
        webUrl: "",
        aligner_change_days: 15,
        total_aligners: "",
        current_aligner: 0,
        next_date_to_take_test: "",
        aligner_restarts_at: alignerData.aligner_restarts_at ? updateRestartStages([...alignerData.aligner_restarts_at]) : [newRestart]
    }

    const validationSchema = Yup.object().shape({
        fileOption: Yup.string().required('Required'),
        file: Yup.mixed().when('fileOption', {
            is: 'upload',
            then: Yup.mixed().required('Please upload a file.').test('is-video', 'Invalid file format. Please upload a mp4 file.', (value) =>
                value && ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'].includes(value.type)
            ).test('fileSize', 'Video size is too large, please select a video of below 200 mb', (value) => !value || value.size <= 150 * 1024 * 1024),
        }),
        webUrl: Yup.mixed().when('fileOption', {
            is: 'webUrl',
            then: Yup.string().required('Required').url('Invalid URL'),
        }),
        next_date_to_take_test: Yup
            .date()
            .required('Required')
            .test('is-future-or-present', 'Date must be in the present or future', function (value) {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Remove time component for accurate comparison
                return value >= currentDate;
            }),
        aligner_change_days: Yup.number().min(1, "Must be more than 0").required("Required"),
        total_aligners: Yup.number().min(1, "Must be more than 0").required("Required"),
        current_aligner: Yup.number().min(0, "Must be positive").required("Required"),
    })


    const UploadFileInput = () => {
        const { values, setFieldValue } = useFormikContext();
        return (
            <>
                {values.fileOption === 'upload' && (
                    <div className=' d-flex flex-column justify-content-center align-items-center '>
                        <label htmlFor="file" className=' file-input bg-primary text-white rounded border border-primary py-1 px-3 ms-3 mt-4'>Browse a mp4 file   </label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
                            accept="video/*"
                            className='hide-input-plan-file-element'
                        />
                        <ErrorMessage name="file" component="div" className="text-danger" />
                        {!values.file && <p className='text-danger'>No File choosen</p>}
                        {values.file && values.file.name &&
                            <div className="d-flex">
                                <p className='text-success fw-bold'>  {values.file.name}  </p>
                            </div>

                        }
                    </div>
                )
                }

                {values.fileOption === "webUrl" && (
                    <div className='w-75'>
                        <label htmlFor="webUrl" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                                Web URL<span className="text-danger">*</span>
                            </span>
                        </label>
                        <Field type="text" id="webUrl" name="webUrl" className="form-input grey-bg-color" placeholder="add your URL here" />
                        <ErrorMessage name="webUrl" component="div" className='text-danger ms-3' />
                    </div>
                )
                }
            </>
        );
    };


    const AlignerNumber = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            const alignerNo = values.current_aligner
            const updatedArray = updateRestartStages(values.aligner_restarts_at, alignerNo)
            setFieldValue('aligner_restarts_at', updatedArray)

        }, [values.current_aligner])

        return (<Col className='d-flex justify-content-center'>
            <div className='w-75'>
                <label htmlFor="current_aligner" className="pt-2 font-weight-500 text-dark field-text mb-1">
                    <span className="field-texts  mx-3">
                        Current Aligner Number<span className="text-danger">*</span>
                    </span>
                </label>
                <Field type="number" min="0" id="current_aligner" name="current_aligner" className="form-input grey-bg-color" placeholder="Current Aligner Number" />
                <ErrorMessage name="current_aligner" component="div" className='text-danger ms-3' />
            </div>
        </Col>

        )
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        const { fileOption, file } = values
        setLoader(true)
        setSubmitting(true);
        if (fileOption === "upload" && file) {
            // converting video file to required file format to store in s3Bucket
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            const formData = new FormData();
            formData.append('video', newFile);
            uploadVideoTos3Bucket({ formData, newFlow: true }).then((res) => {

                if (JSON.stringify(res.data.data) === '{}') {
                    setLoader(false)
                    setsnackbarmessage(res.data.message);
                    settype("error");
                    setsnackbar(true);

                } else {
                    const videoDetails = res.data.data
                    updateAlignerUserFlow({ ...values, ...videoDetails, user_id }).then((res) => {
                        setsnackbarmessage(res.data.message);
                        if (JSON.stringify(res.data.data) === '{}') {
                            settype("error")

                        } else {
                            setsnackbarmessage("Aligner restarted successfully");
                            settype("success");
                            setSubmitButtonText("Restarted Successfully")
                            reload()
                            setTimeout(() => {
                                back();
                            }, 2000);

                        }
                        setLoader(false)
                        setsnackbar(true);
                    }).catch((err) => {
                        setLoader(false)
                        setsnackbarmessage("Something went wrong, please try again later.");
                        setsnackbar(true);

                    })
                }
            }).catch((err) => {
                setLoader(false)
                console.log("error")
                setsnackbarmessage("uploading mp4 file failed please try again with valid mp4 file");
                settype("error");
                setsnackbar(true);

            })
        } else {
            setLoader(true)
            updateAlignerUserFlow({ ...values, user_id }).then((res) => {
                setsnackbarmessage(res.data.message);
                if (JSON.stringify(res.data.data) === '{}') {
                    settype("error")

                } else {
                    setsnackbarmessage("Aligner restarted Successfully");
                    settype("success");
                    setSubmitButtonText("Restarted Successfully")
                    reload()
                    setTimeout(() => {
                        back();
                    }, 2000);
                }
                setLoader(false)
                setsnackbar(true);
            }).catch((err) => {
                console.log(err)
                settype("error")
                setLoader(false)
                setsnackbarmessage("Something went wrong, please try again later.");
                setsnackbar(true);

            })

        }



    }




    return (
        <>

            <button type="button" className="bg-white rounded ms-4 border border-primary mt-4" onClick={() => { back(false) }} > <IoIosArrowBack className="mb-1" /> </button>

            {snackbar && (
                <SnackFire
                    open={snackbar}
                    onClose={() => { setsnackbar(false) }}
                    position="top-right"
                    timing={4000}
                    type={type}
                    message={snackbarmessage}
                />
            )}
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {
                    ({ isSubmitting }) =>
                    (
                        <Form>
                            <Container>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="total_aligners" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Total Number of Aligners<span className="text-danger">*</span>
                                                </span>
                                            </label>
                                            <Field type="number" min="1" id="total_aligners" name="total_aligners" className="form-input grey-bg-color" placeholder="Total Number of Aligners" />
                                            <ErrorMessage name="total_aligners" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="aligner_change_days" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Aligner Change Period(days)<span className="text-danger">*</span>
                                                </span>
                                            </label>
                                            <Field type="number" min="1" id="aligner_change_days" name="aligner_change_days" className="form-input grey-bg-color" placeholder="Number of Days" />
                                            <ErrorMessage name="aligner_change_days" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <AlignerNumber />
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="next_date_to_take_test" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Next Check Date<span className="text-danger">*</span>
                                                </span>
                                            </label>
                                            <Field type="date" id="next_date_to_take_test" name="next_date_to_take_test" className="form-input  grey-bg-color" placeholder="Next Check Date" />
                                            <ErrorMessage name="next_date_to_take_test" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="fileOption" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Select Plan File<span className="text-danger">*</span>
                                                    </span>
                                                </label>
                                                <Field as="select" id="fileOption" name="fileOption" placeholder="Select Plan File" className="form-input  grey-bg-color" >
                                                    <option value=""> Select Plan File</option>
                                                    <option value="upload">Upload a mp4 File</option>
                                                    <option value="webUrl">Web URL</option>
                                                </Field>
                                                <ErrorMessage name="fileOption" component="div" className='text-danger ms-3' />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className='d-flex justify-content-center'>
                                        <UploadFileInput />
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col>
                                        <div className='d-flex justify-content-center mt-4'>
                                            <button type="submit" className="submit-button px-5 mb-4 border-0" >
                                                {loader ? <div className="text-center">
                                                    <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div> : <span> {submitButtonText} </span>}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )
                }
            </Formik>

        </>

    )
}


export default RestartPlan