import * as React from 'react';
import { useState } from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConclusionDialog = (props) => {
  const { open, setOpen, completeTreatment } = props
  const [loader, setLoader] = useState(false)

  const handleClose = () => {
    setOpen(false);

  };
  const submit = () => {
    setLoader(true)
    completeTreatment()

  }


  return (
    <div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='text-primary'>
          {"Completion of patient treatment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>

            Looks like the patient has completed his/her treatment with given set of aligners.
            Would you prefer them to start with retainers ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='text-primary'>Disagree</Button>
          <Button onClick={submit} autoFocus className='text-primary'>
            {loader ? <div className="text-center">
              <div className="spinner-border spinner-border-sm text-primary mx-4" role="status">
                <span className="sr-only"></span>
              </div>
            </div> : <span> Agree </span>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default ConclusionDialog