import * as React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import "./index.css"


const ReloadApp = (props) => {
    const { reload, version } = props
    const [update, setUpdate] = useState(false)
    const [percentage, setPercentage] = useState(0)

    const reloadEntirePage = () => {
        setUpdate(true)
        const intervalId = setInterval(() => {
            setPercentage(prevCount => {
                const newCount = prevCount + 20
                if (newCount >= 100) {
                    clearInterval(intervalId)
                    setTimeout(() => {
                        localStorage.setItem("es", version)
                        window.location.reload();
                    }, 1000)
                }
                return newCount
            })

        }, 300);


    };


    const handleClose = () => {
    };

    return (
        <div>
            <Dialog
                open={reload}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <div className='font-DM-Sans upload-box-container'  >
                    <div className='p-3'>
                        <div>
                            <h5> New Update available!</h5>
                        </div>
                    </div>
                    <div >
                        <div>
                            {
                                !update ? <p className='px-3'> A new update is available. Please click on update to proceed.</p>
                                    :
                                    <div className='px-3' >
                                        <div className="progress" style={{ height: "6px" }}>
                                            <div className="progress-bar progress-bar-warning  bg-primary " role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentage}%` }}>
                                            </div>

                                        </div>
                                        <p className="d-flex justify-content-end my-2 mb-4">{percentage}%</p>
                                    </div>

                            }
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className='d-flex justify-content-end '>
                            {!update && <button onClick={reloadEntirePage} className='text-primary aligncheck-update-button border-0 p-2 px-4 my-3 rounded' > Update</button>}
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default ReloadApp