import React, { useState } from "react";
import { useParams } from "react-router-dom";

import close from "../../assets/Close.svg";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { submitAligncheckScan } from "../../Services/AlignerService";
import SnackFire from "../../UI/Snackbar/Snackbar";

import "./alignerScans.css"
import "../AlignerDashboard/alignerDashboard.css"
import TokenExpired from "../TokenExpired";

const ReviewPopup = ({ setOpen, alignerData, selectedReport, latestReport, reloadUserDetails, totalReports, setConclusionDialog }) => {
    let { user_id } = useParams()
    const [scanStatus, setScanStatus] = useState('')
    const [error, setError] = useState(false)
    const [days, setDays] = useState('')
    const [daysError, setdaysError] = useState(false)
    const [remarks, setRemarks] = useState(selectedReport?.remarks)
    const [includeRemarks, setIncludeRemarks] = useState(false)
    const [loader, setLoader] = useState(false)
    const [snackbarmessage, setsnackbarmessage] = useState('');
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");

    const updateStatus = status => {
        if (selectedReport) {
            if (selectedReport?._id === latestReport?._id) {
                setScanStatus(status)
                setError(false)
                setdaysError(false)

            } else {
                setsnackbarmessage("Please select the latest scan taken on the prescribed date.")
                settype("warning")
                setsnackbar(true)
            }
        } else {
            setsnackbarmessage("The user has not yet shared the scan.")
            settype("warning")
            setsnackbar(true)
        }

    }
    const updateDays = event => {
        setDays(event.target.value)
        setdaysError(false)
    }

    const getData = (days, scanStatus) => {
        let updatedDays = alignerData?.aligner_change_days
        let updatedDate = selectedReport?.createdAt
        let updatedCurrentAligner = alignerData?.current_aligner
        switch (scanStatus) {
            case 1:
                updatedDays = alignerData?.aligner_change_days
                updatedCurrentAligner = selectedReport?.aligner_number
                break;
            case 2:
                updatedDays = days;
                updatedCurrentAligner = (selectedReport.aligner_number === alignerData?.current_aligner && alignerData?.current_aligner !== 0) ? (alignerData?.current_aligner - 1) : alignerData?.current_aligner
                break;
            case 3:
                updatedDays = 0;
                updatedDate = new Date()
                updatedCurrentAligner = (selectedReport.aligner_number === alignerData?.current_aligner && alignerData?.current_aligner !== 0) ? (alignerData?.current_aligner - 1) : alignerData?.current_aligner
                break;
            default:
                break;
        }

        const resultDate = new Date(updatedDate)
        resultDate.setDate(resultDate.getDate() + parseInt(updatedDays))

        return { resultDate, updatedCurrentAligner }


    }

    const submitScan = () => {
        setLoader(true)
        const { resultDate, updatedCurrentAligner } = getData(days, scanStatus)
        const scanDetails = {
            reportId: selectedReport?._id,
            user_id,
            status_by_doctor: scanStatus,
            is_doctor_reviewed: true,
            days: scanStatus === 2 ? days : '',
            remarks,
            includeRemarks,
            next_date_to_take_test: resultDate,
            current_aligner: updatedCurrentAligner,
            emailDate: moment(new Date(resultDate)).format('YYYY-MM-DD')
        }
        if (scanStatus === 1 && totalReports.length > 1 && selectedReport?.aligner_number === 0) {   // where aligner number  ==  0 does mean that it's a Retainer
            setConclusionDialog(true)
            setLoader(false)
        } else {
            submitAligncheckScan(scanDetails).then((res) => {
                setLoader(false)
                setsnackbarmessage("Scan submitted successfully")
                settype("success")
                setsnackbar(true)
                reloadUserDetails(scanDetails)
                setTimeout(() => {
                    setOpen(false)
                }, 2000);
            }).catch((err) => {
                setLoader(false)
                setsnackbarmessage("Something went wrong")
                settype("error")
                setsnackbar(true)
            })
        }

    }


    const handleSubmit = event => {
        event.preventDefault();
        if (selectedReport) {
            if (latestReport?._id === selectedReport?._id) {
                if (!scanStatus) {
                    setError(true)
                } else if (scanStatus === 1 || scanStatus === 3) {
                    submitScan()
                } else if (scanStatus === 2 && (!days || days < 1)) {
                    setdaysError(true)
                } else if (scanStatus === 2 && days) {
                    submitScan()
                }

            } else {
                setsnackbarmessage("Please select the latest scan taken on the prescribed date.")
                settype("warning")
                setsnackbar(true)
            }

        } else {
            setsnackbarmessage("The user has not yet shared the scan.")
            settype("warning")
            setsnackbar(true)

        }
    }

    const Recommendation = ({ status }) => {
        let text = 'Pending'
        let styles = 'pending'

        switch (status) {
            case 1:
                text = "Go";
                styles = "aligncheck-reviewed-go"
                break;
            case 2:
                text = "No Go";
                styles = "aligncheck-reviewed-no-go"
                break;
            case 3:
                text = "Reshoot";
                styles = "aligncheck-reviewed-reshoot"
                break;
            default:
                text = "Pending";
                styles = "aligncheck-reviewed-pending"
                break;

        }

        return (
            <div className="d-flex flex-column align-items-center">
                <span className="dark-blue" style={{ fontSize: "15px" }}>Recommendation </span>
                <span className={`${styles}  px-2 rounded-fill  fw-bold text-center pt-1 rounded-pill`} style={{ width: "100px", fontSize: "17px" }}> {text}  </span >
            </div>

        )

    }



    return (
        <div className="review-popup-container d-flex justify-content-center align-items-center">
            {snackbar && (
                <SnackFire
                    open={snackbar}
                    onClose={() => { setsnackbar(false) }}
                    position="top-right"
                    timing={4000}
                    type={type}
                    message={snackbarmessage}
                />
            )}
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            <form onSubmit={handleSubmit}>
                <div className="review-popup-sub-container rounded">
                    <div className="d-flex align-items-center mt-2">
                        <h4 className="text-primary  flex-fill text-center m-0 "> <span className=" border-bottom border-primary px-4 border-2 pb-2 rounded">  Review Aligncheck Scan </span></h4>
                        <button type="button" className='bg-transparent border-0 ' onClick={() => { setOpen(false) }}>
                            <img src={close} width="50px" alt="close" height="35px"></img>
                        </button>
                    </div>
                    <div className="d-flex mt-4 justify-content-between mx-4">
                        <div className="">
                            <p className="dark-blue">Aligner info</p>
                            <ul className="light-blue">
                                <li style={{ width: "160px" }}>
                                    <div className="d-flex justify-content-between" >
                                        <span > {`Scanned ${selectedReport?.aligner_number === 0 ? "with" : "aligner"} :`} </span>
                                        <span className="dark-blue"> {selectedReport ? selectedReport?.aligner_number === 0 ? "R" : `${selectedReport?.aligner_number}` : ""} </span>
                                    </div> </li>
                                <li className="">

                                    <div className="d-flex justify-content-between" >
                                        <span> Current aligner : </span>
                                        <span className="dark-blue"> {alignerData?.current_aligner} </span>
                                    </div>
                                </li>
                                <li className="">
                                    <div className="d-flex justify-content-between" >
                                        <span> Total aligners : </span>
                                        <span className="dark-blue"> {alignerData?.total_aligners} </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="me-3">
                            {selectedReport ? (selectedReport?.is_report_reviewed ? <Recommendation status={selectedReport?.status_by_annotator} /> : <Recommendation status={"pending"} />) : null}
                        </div>
                    </div>
                    <div className="mx-4">
                        <label className="dark-blue"> Select</label>
                        <div className='d-flex justify-content-start align-items-center my-1 mx-3' >
                            {scanStatus && <button type="button" className="bg-white rounded border-0" onClick={() => { updateStatus("") }} > <IoIosArrowBack className="mb-1" /> </button>}
                            {(scanStatus === 1 || !scanStatus) && <button type="button" className={`mx-2 submit-button-common py-1 ${scanStatus === 1 ? "active-go" : "go"}  px-4 shadow rounded`} value={1} onClick={() => { updateStatus(1) }} >  Go</button>}
                            {(scanStatus === 2 || !scanStatus) && <button type="button" className={`mx-2 submit-button-common py-1 ${scanStatus === 2 ? "active-no-go" : "no-go"} shadow rounded`} value={2} onClick={() => { updateStatus(2) }} >  No Go</button>}
                            {(scanStatus === 3 || !scanStatus) && <button type="button" className={`mx-2 submit-button-common py-1 ${scanStatus === 3 ? "active-reshoot" : "reshoot"} shadow rounded`} value={3} onClick={() => { updateStatus(3) }} > Reshoot </button>}
                            {(scanStatus === 2) && <div>
                                <label style={{ fontSize: "14px" }}>Specify how long to continue with the current aligner.</label>
                                <input type="number" min="1" className="rounded ms-3 ps-2" style={{ width: "70px" }} placeholder="days" value={days} onChange={(e) => { updateDays(e) }} />
                            </div>}
                        </div>
                        {error && <p className="text-danger ms-4">Please select scan status</p>}
                        {daysError && <p className="text-danger ms-4 text-center">Days must be positive</p>}

                    </div>
                    <div className="mx-4 my-2">
                        <label className="dark-blue my-1">Remarks</label>
                        <textarea type="text" cols={5} rows={5} className="w-100 px-2 py-1 rounded" placeholder="Add any additional remarks here..." value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                        <input type="checkbox" id="remarks-check-box" className="form-check-input" onChange={(e) => setIncludeRemarks(e.target.checked)} /> <label htmlFor="remarks-check-box" className="ms-1" style={{ fontSize: "14px" }}> Include Remarks in Email</label>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mx-4">
                        {latestReport?._id !== selectedReport?._id && <p className="alert-info-aligncheck flex-fill m-0 me-5  py-3 ps-2 rounded"> <span className="fw-bold me-2"> Note: </span>  Only the latest scan is accessible for review.</p>}
                        <button type="submit" className={`btn btn-primary shadow me-2 px-4 ${latestReport?._id === selectedReport?._id && selectedReport ? "" : "no-drop"}`} disabled={loader}>
                            {loader ? <div className="text-center">
                                <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div> : <span> Submit</span>} </button>
                    </div>
                </div>
            </form>

        </div>
    )
}


export default ReviewPopup