
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { logoutUserService } from '../../Services/AlignerService';
import { useNavigate } from "react-router-dom";
import { clearAppDetails } from '../../Store/appDetails';
import { clearLogo } from '../../Store/appLogo';
import { clearDoctors } from '../../Store/doctors';
import "./index.css"

const Logout = ({ open, setLogout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()


    const handleClose = () => {
        setLogout(false)
    };

    const logOutUser = async () => {
        await logoutUserService()
        dispatch(clearAppDetails())
        dispatch(clearLogo())
        dispatch(clearDoctors())
        navigate("/login")
    }

    return (
        <div className='bg-gray'>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <div className='font-DM-Sans log-out-container d-flex flex-column align-items-center justify-content-evenly'  >
                    <p className='px-3 align-self-center '>  Are you sure you want to logout?       </p>
                    <div className='align-self-stretch d-flex justify-content-center  mx-3 px-5'>
                        <button type="button" className='btn border border-primary bg-white' onClick={handleClose}> Cancel  </button>
                        <button type="button" className='btn btn-primary px-3 ms-4 ' onClick={logOutUser}> Log out </button>
                    </div>

                </div>
            </Dialog>
        </div>
    );
}

export default Logout