import { configureStore } from '@reduxjs/toolkit'
import appDetailsReducer from './appDetails'
import appLogoReducer from "./appLogo"
import doctorReducer from "./doctors"

export const store = configureStore({
    reducer: {
        appDetails: appDetailsReducer,
        appLogo: appLogoReducer,
        doctors: doctorReducer
    },
})

export default store