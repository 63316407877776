import React, { useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/ToothLensLogo.png";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  loginAdmin,
  resendRegisterCodeService,
  getLatestClinicDetails,
  logoutUserService,
  updateDentistPasswordForFirstTime
} from "../../Services/AlignerService";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SnackFire from "../../UI/Snackbar/Snackbar";
import { Call } from "@mui/icons-material";

const FirstLogin = () => {
    const navigate = useNavigate();
    const [showPassword, setshowPassword] = useState(false);
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    // const [user,setuser]=useState("")
    const user = JSON.parse(localStorage.getItem("admin"));

    const Call = (loginResponse) => {
      localStorage.setItem("authToken", loginResponse.headers.authorization);
      localStorage.setItem("admin", JSON.stringify(loginResponse.data.data));
      // setuser(JSON.parse(localStorage.getItem("admin"))) ;
                    if (user.admin.company.length != 0)
                    {
                      if(user.admin.is_chief_admin){
                        navigate("/dashboard-dental");
                      }else if (!user.admin.is_chief_admin){
                        if(user.admin.is_first_login){
                          navigate("/first-login");
                        }else{
                          navigate("/dashboard-dentist");
                        }
                      }
             }else
              {
               navigate("/account-details");
             }
    };
    return (
      <Container fluid className="">
        {
          <Formik
            validate={(values) => {
              let errors = {};
  
              if (!values.password) {
                errors.password = "Password is required";
              }
              if (!values.email) {
                errors.email = "Email ID is required";
              }
              if (values.password!=values.confirmPassword) {
                errors.confirmPassword = "Passwords do not match";
              }
              return errors;
            }}
            validationSchema={Yup.object().shape({    
              password: Yup.string().required("Password is required"),
              email: Yup.string().email().required(" Email is Required"),
            })}
            initialValues={{
              email: user.admin.email,
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values) => {
            updateDentistPasswordForFirstTime(values).then(res=>{
                logoutUserService().then(res=>{
                    navigate("/login")
                }).catch(err=>{
                    console.log(err)
                })
            }).catch(err=>{
                console.log(err)
            })
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              const handleClickShowPassword = () => {
                setshowPassword(!showPassword);
              };
  
              const handleclose = () => {
                setsnackbar(false);
              };
              return (
                <form onSubmit={handleSubmit}>
                  {snackbar && (
                    <SnackFire
                      open={snackbar}
                      onClose={handleclose}
                      position="top-right"
                      timing={5000}
                      type={type}
                      message={snackbarmessage}
                    />
                  )}
                  <Row>
                  <Col md={6} xs={12} className="signup_image">
                      <div className=" d-flex w-5 h-100 justify-content-center align-items-center">
                        <img
                          className="img-fluid rounded"
                          src={ToothScan}
                          alt="Scan"
                        />
                      </div>
                      <div className="image d-flex justify-content-center py-5 signup-footer">
                        Powered by Toothlens
                      </div>
                    </Col>
  
                    <Col
                      md={6}
                      xs={12}
                      className=" d-flex justify-content-center align-items-center position"
                    >
                      <div className="position-absolute">
                        {/* <img src={Logo} className="images" alt="Logo" /> */}
                      </div>
  
                      <div>
                        <Col md={6}>
                          <FormLabel
                            component="legend"
                            className=" font-weight-500 text-dark font-style"
                          >
                            <span className="field-texts">Email Address</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1">
                          {" "}
                          <input
                            id="email"
                            className="form-input "
                            placeholder="johndow@gmail.com"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </Col>
  
                        <Col>
                          <FormLabel
                            component="legend"
                            className="mt-3 font-weight-500 text-dark font-style"
                          >
                            <span className="field-texts"> New Password</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1">
                          <div className="position-relative">
                            <input
                              id="password"
                              className="form-input mb-3 grey-bg-color"
                              placeholder="xxxxx"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span
                              className={`show-passwords`}
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </span>
                          </div>
  
                          {errors.password && touched.password && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.password}
                            </div>
                          )}
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="mt-3 font-weight-500 text-dark font-style"
                          >
                            <span className="field-texts"> Confirm   Password</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1">
                          <div className="position-relative">
                            <input
                              id="confirmPassword"
                              className="form-input mb-3 grey-bg-color"
                              placeholder="xxxxx"
                              type={"password"}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span
                              className={`show-passwords`}
                              onClick={handleClickShowPassword}
                            >
                              {/* {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )} */}
                           {/* <VisibilityOffIcon /> */}

                            </span>
                          </div>
  
                          {errors.confirmPassword && touched.confirmPassword && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </Col>
                       
                        <Col className="mt-4">
                          <Button className="form-btn" type="submit">
                            Submit
                          </Button>
                        </Col>
                       
                      </div>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        }
      </Container>
    );
}

export default FirstLogin