import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./dentist.css";
import AddDentist from "./addDentist";
import DentistList from "./dentistList";
import Navbars from "../Navbar/navbar";
import NavigationMenu from "../NavigationMenu";

const Dentist = () => {
  const [change, setchange] = useState(false);
  const [running, setrunning] = useState(false);
  const Torender = () => {
    setrunning(false)
    setchange(!change)
  }

  useEffect(() => {
    setrunning(true);
  }, [change]);
  useEffect(() => {
    setrunning(true);
  }, []);

  return (
    <Container fluid className="team-details-main-container">
      <Navbars header={"Team Details"} />
      <div className="d-flex">
        <NavigationMenu active={"team"} />


        {running ?
          <Row className="flex-grow-1">
            <Col className="" xs={12} md={12} sm={12} lg={4}>
              <div className="filter-location m-2 d-flex justify-content-center align-items-center">
                <AddDentist change={Torender} />
              </div>
            </Col>
            <Col className="" xs={12} sm={12} md={12} lg={8}>
              <div className="list m-2">
                <DentistList change={Torender} />
              </div>
            </Col>
          </Row> : <div className="d-flex justify-content-center mt-5 w-100 h-100">
            <div className="spinner-border spinner-border mx-4 text-primary mt-5" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        }
      </div>


    </Container>
  );
}


export default Dentist;
