import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Components/LandingPage/landingPage";
import Login from "./Components/Login/login";
import SignUp from "./Components/SignUp/signUp";
import SignUpStepTwo from "./Components/SignUp/signUpStepTwo";
import ForgotPassword from "./Components/Login/forgotPassword";
import VerifyCode from "./Components/Login/verifyCode";
import NewPassword from "./Components/Login/newPassword";
import Thanks from "./Components/ThanksForSignUp/thanks";
import Dentist from "./Components/dentist/dentist";
import FirstLogin from "./Components/Login/firstLogin";
import AlignerDashboard from "./Components/AlignerDashboard";
import AlignerUserDetails from "./Components/AlignerPatientReports/alignerPatientReport";
import OfficeDetails from "./Components/OfficeDetails";

const App = () => {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="signup/:signupReferral_id" element={<SignUp />} />
        <Route path="verify-code" element={<VerifyCode />} />
        <Route path="signup-step-two" element={<SignUpStepTwo />} />
        <Route path="account-details" element={<OfficeDetails />} />
        <Route path="signupsucess" element={<Thanks />} />
        <Route path="dentist" element={<Dentist />} />
        <Route path="first-login" element={<FirstLogin/>} />
        <Route path="aligner-dashboard" element={<AlignerDashboard />} />
        <Route path="aligner-reports/:user_id" element={<AlignerUserDetails />}  />
      </Routes>
    </BrowserRouter>
    </div>
  );
};

export default App;
