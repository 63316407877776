import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import "../AlignerDashboard/dashboard.css"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";

import {
  getAlignerUsers,
  getAlignerReportsForCsv, getAlignerPartnerVersion
} from "../../Services/AlignerService";
// import Navbars from "../Dashboard_USA/navbar";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { FaDownload } from "react-icons/fa";

import SignUpForm from "./SignUpFormContainer";
import UpdateDentistPopup from "./updateDentistPopup";
import NavigationMenu from "../NavigationMenu";
import Navbars from "../Navbar/navbar";
import "./dashboard.css"
import ReloadApp from "../NewUpdate";
import "../AlignerPatientReports/alignerScans.css"
import SnackFire from "../../UI/Snackbar/Snackbar";
import TokenExpired from "../TokenExpired";
import { getDoctors } from "../../Store/doctors";
import Loader from "../Loader";
import SkeletonLoader from "./skeleton";

const AlignerDashboard = () => {
  const dispatch = useDispatch()
  const { doctors, fetched } = useSelector((state) => state.doctors)
  const [patientsLoading, setPatientsLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({})       //   userId for updating the assign dentist for respective users based on user id
  const [assignedDentist, setassignedDentistId] = useState("")   //  used to popup in updateDentist
  const [loading, setLoading] = useState(true);
  const app = JSON.parse(localStorage.getItem("xld")).app;
  const [doctorfilterinfo, setdoctorfilterinfo] = useState([]);
  const [patientsWithoutReports, setPatientsWithoutReports] = useState(false)
  const [goPatients, setGopatients] = useState()
  const [doctorSubmitted, setDoctorSubmitted] = useState()
  const [patienttype, setpatienttype] = useState();
  const [openedReport, setOpenedReport] = useState();
  const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
  const [change, setchange] = useState(false);
  const [sorting, setsorting] = useState();
  const [csvdata, setcsvdata] = useState("");
  const [userOpenedCount, setUserOpenedCount] = useState(0);
  const [userUnOpenedCount, setUserUnOpenedCount] = useState(0);
  const [totalusers, settotalusers] = useState(0);
  const [filteredUsersCount, setFilteredUsersCount] = useState(0)
  const [currentpage, setcurrentpage] = useState(0);
  const [totalpage, setTotalPAge] = useState(0)
  const [iSignUpFormOpen, setSignUpForm] = useState(false)
  const [showFilters, setFilters] = useState(false)
  const [showPatientSummary, setPatientSummary] = useState(true)
  const [reload, setReload] = useState(false)
  const [updatedVersion, setUpdatedVersion] = useState("")
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");


  useEffect(() => {
    !fetched && dispatch(getDoctors({ app, doctorRole: "63d93e90dae984f675862ad4" }))
  }, [])


  const updateAppVersion = async () => {
    const version = localStorage.getItem("es")
    await getAlignerPartnerVersion().then((res) => {
      if (res.data.data !== "No Data") {

        const newVersion = res.data.data.version
        if (!version || newVersion !== version) {
          setReload(true)
          setUpdatedVersion(newVersion)
        }

      }

    }).catch((err) => {
      console.log(err)
    })
  }


  const initialCall = async () => {
    const object = { doctorfilterinfo, currentpage, app, patienttype, openedReport, sorting, goPatients, doctorSubmitted, patientsWithoutReports };

    setPatientsLoading(true)
    await getAlignerUsers(object)
      .then((res) => {
        const { totalUsersCount, filteredUsersCount, alignerUsers, reportViewedUsercount } = res.data.data

        setUsers(alignerUsers)
        settotalusers(totalUsersCount)
        setTotalPAge(Math.ceil(filteredUsersCount / 8));
        setFilteredUsersCount(filteredUsersCount)
        setUserOpenedCount(reportViewedUsercount)
        setUserUnOpenedCount(totalUsersCount - reportViewedUsercount)
        setLoading(false);
        setPatientsLoading(false)

      })
      .catch((err) => {
        console.log(err)
        setsnackbarmessage("Something went wrong retrieving users")
        settype("error")
        setsnackbar(true)
        setLoading(false);
        setPatientsLoading(false)
      })

    await updateAppVersion()

    // await getAlignerReportsForCsv(object)
    //   .then((res) => {
    //     const updatedCsvData = res.data.data.csvReports.map(eachUser => {
    //       const updateUser = {
    //         ...eachUser, is_report_shared: eachUser.userReportsData.length > 0
    //       }
    //       return updateUser
    //     })
    //     setcsvdata(updatedCsvData)

    //   })
    //   .catch((err) => {
    //     console.log("error at csv")
    //   })

  };


  useEffect(() => {
    initialCall();
  }, [doctorfilterinfo, currentpage, app, patienttype, openedReport, sorting, goPatients, doctorSubmitted, patientsWithoutReports]);


  const handlesorting = (e) => {
    setsorting(e.target.value);
    setchange(!change);
  };

  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setchange(!change);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setchange(!change);
  };

  const getDoctorName = id => {
    const doctor = doctors.find(doctorObj => doctorObj._id == id)
    const doctorName = doctor ? `Dr. ${doctor.name}` : "--"
    return doctorName
  }

  const closeAssigndentistPopup = () => {
    setdoctorfilterinfo([]);
    setsorting("");
    setcurrentpage(0);
    initialCall();
    setassignedDentistPopup(false);
  };



  const handleDoctorFilterChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setdoctorfilterinfo([...doctorfilterinfo, value]);
      setchange(!change);
    }
    else {
      const filteresFoctors = doctorfilterinfo.filter((e) => e !== value)
      setdoctorfilterinfo(filteresFoctors);
      setchange(!change);
    }
  };

  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  // ----------------------------------CSV headers for shared users---------------------

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_number" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Is Report Viewed", key: "is_report_viewed" },
    { label: "Is Report Shared", key: "is_report_shared" }
  ];
  const csvReport = {
    data: csvdata,
    headers: headers,
    filename: "Shared_Users.csv",
  };



  const Status = (props) => {
    const { reportGoStatus, isDoctorReviewed } = props
    let text = "Pending"
    let styles = "aligncheck-reviewed-pending"

    switch (reportGoStatus) {
      case 1:
        text = "Go";
        styles = "aligncheck-reviewed-go";
        break;
      case 2:
        if (isDoctorReviewed) {
          text = "No Go";
          styles = "aligncheck-reviewed-no-go";
        } else {
          text = "Pending";
          styles = "aligncheck-reviewed-pending";
        }
        break;
      case 3:
        text = "Reshoot"
        styles = "aligncheck-reviewed-reshoot"
        break;
      default:
        text = "Pending"
        styles = "aligncheck-reviewed-pending"
        break;
    }
    return (
      <p className={`${styles} status-common rounded-2 text-center py-1 m-0`}> {text}</p>
    )
  }

  const User = (props) => {
    const { is_report_viewed, next_date_to_take_test, total_aligners,
      current_aligner, treatment_completed, is_doctor_reviewed, report_go_status, profile } = props.details
    const { _id, first_name, last_name, email } = profile
    const nextCheckDate = new Date(next_date_to_take_test)
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = nextCheckDate.toLocaleDateString("en-US", options);
    const percentage = treatment_completed ? 100 : Math.floor((current_aligner / total_aligners) * 100)
    const currentAligner = treatment_completed ? total_aligners : current_aligner

    return (
      <div>
        <Container fluid className="rounded">
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            to={`/aligner-reports/${_id}`}
          >
            <Row className="m-2 aligner-user-container rounded">
              <Col sm={8}>
                <Row>
                  <Col className="d-flex flex-column justify-content-evenly" sm={4}>
                    <span className={`${is_report_viewed ? "text-sans-bold  font-32-ellipsis text-primary" : "text-sans-bold font-12-ellipsis "} aligner-user-name  text-truncate my-2`}> {first_name} {last_name}</span>
                    <p className="text-sans-bold text-truncate align-self-stretch"> {email} </p>
                  </Col>
                  <Col sm={8} className="">
                    <p className="text-sans-bold my-1 ">Progress</p>
                    <div className="progress" style={{ height: "6px" }}>
                      <div className="progress-bar progress-bar-warning  bg-primary " role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentage}%` }}>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font-size-13 text-secondary font-DM-Sans mt-1"> Next check: <span className="text-primary fw-bold ms-1"> {formattedDate} </span> </p>
                      <p className="font-size-15 text-success fw-bold font-DM-Sans">{`${percentage}%`}</p>

                    </div>
                  </Col>
                </Row>
              </Col>

              <Col className="" sm={4}>
                {
                  treatment_completed ?
                    <div className="d-flex align-items-center justify-content-center h-100 me-3">
                      <p className="aligncheck-reviewed-go rounded-2 text-center py-1 px-4 m-0 fw-bold"> <FaRegCheckCircle className="me-2 mb-1" />  Treatment completed </p>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-evenly h-100 ">
                      <p className="current-aligner font-DM-Sans font-size-13 text-secondary m-0"> Current Aligner: <span className="badge text-bg-primary font-size-15 ms-2">{currentAligner}</span></p>
                      <Status reportGoStatus={report_go_status} isDoctorReviewed={is_doctor_reviewed} />
                    </div>
                }
              </Col>
            </Row>
          </Link>

        </Container>
      </div>

    )
  }


  const PatientSummary = () => {
    return (
      <div className="font-DM-Sans ms-2">
        <p className="patient-summary mx-3 text-dark"> Patients Summary <button type="button" className="bg-white border-0" onClick={() => { setPatientSummary(!showPatientSummary) }}> {showPatientSummary ? <IoIosArrowDropup /> : <IoIosArrowDropdown />} </button>  </p>
        <div className={`align-patient-summary patient-summary-main-container font-DM-Sans ms-2 d-flex px-3 justify-content-between ${showPatientSummary ? "patient-summary-expand" : ""}`}>
          <div className="summary-section d-flex flex-column  justify-content-center">
            <p className="status-number "> {totalusers} </p>
            <button type="button" className={` summary-button  rounded-2 ${openedReport == null ? "total-bg " : "border"} align-total`} onClick={() => {
              setdoctorfilterinfo([]);
              setsorting("");
              setOpenedReport();
              setcurrentpage(0);
              setpatienttype()
              setGopatients()
              setDoctorSubmitted()
              setPatientsWithoutReports(false)
            }}>Total cases</button>
          </div>
          <div className="summary-section text-primary">
            <p className="status-number"> {userOpenedCount} </p>
            <button type="button" className={` summary-button rounded-2 ${openedReport ? "align-opened-active " : "border"} align-open`} onClick={() => {
              setOpenedReport(true);
              setchange(!change);
            }}>Opened</button>
          </div>
          <div className="summary-section text-danger">
            <p className="status-number"> {userUnOpenedCount} </p>
            <button type="button" className={`  summary-button rounded-2 ${openedReport === false ? "align-un-opened-active " : "border"} align-un-open`} onClick={() => {
              setOpenedReport(false);
              setchange(!change);
            }}>Unopened</button>
          </div>
        </div>
      </div>
    )
  }



  return (
    <Container fluid className="aligncheck-home-container">
      <Navbars header={"Aligncheck Dashboard"} />
      {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
      <Row className="aligncheck-home-sub-container">
        <div className="d-flex">
          <NavigationMenu active={"dashboard"} />
          {
            loading ? <Loader /> :
              <div className="d-flex flex-grow-1 ">
                <div className={`filter-popup-container shadow rounded-2 ${showFilters ? "expand" : ""}`}>
                  <Row className="d-flex align-items-center mt-2">
                    <Col md={4} xs={4}>
                      <div className="d-flex justify-content-start align-items-center mx-2  ">
                        {" "}
                        <FilterAltIcon className="text-primary" />
                        <div className=" mx-1  d-flex justify-content-start patient-summary text-primary">
                          Filter
                        </div>
                      </div>
                    </Col>
                    <Col md={7} xs={7} className=" d-flex w-5 justify-content-end text-reset-all" >
                      <button
                        type="button"
                        className="clear-align-filters font-DM-Sans rounded"
                        onClick={() => {
                          setdoctorfilterinfo([]);
                          setsorting("");
                          setcurrentpage(0);
                          setpatienttype();
                          setOpenedReport();
                          setGopatients()
                          setDoctorSubmitted()
                          setPatientsWithoutReports(false)
                        }}
                      >
                        Clear Filters
                      </button>
                    </Col>
                  </Row>



                  <div className="font-DM-Sans filters-parent-container " >
                    <p className="filter-heads">Missed checks</p>
                    <div className="radio-btn ms-3" >
                      <label className="d-flex ">
                        <input
                          type="checkbox"
                          value={patientsWithoutReports}
                          name="patients-without-Reports"
                          checked={patientsWithoutReports}
                          className="form-check-input"
                          onChange={() => { setPatientsWithoutReports(!patientsWithoutReports) }}
                        />  <span className="ms-2">Patients missed latest check</span></label>
                    </div>
                    <p className="filter-heads">Patient examination</p>
                    <div >
                      <div className="radio-btn ms-4" onClick={() => { setDoctorSubmitted("true") }}>
                        <label className="d-flex ">
                          <input type="radio" value={doctorSubmitted} name="doctorSubmitted" checked={doctorSubmitted == "true"} />
                          <span className="ms-2">Checks submitted</span>
                        </label>
                      </div>
                    </div>

                    <div >

                      <div className="radio-btn ms-4" onClick={() => {
                        setDoctorSubmitted("false");
                        setGopatients()
                      }} >
                        <label className="d-flex ">
                          <input type="radio" value={doctorSubmitted} name="doctorSubmitted" checked={doctorSubmitted == "false"} />
                          <span className="ms-2"> Checks not submitted </span>   </label>
                      </div>
                    </div>
                    <p className="filter-heads">Latest status</p>
                    <div >
                      <div className="radio-btn ms-4" onClick={() => {
                        setGopatients(1);
                        setDoctorSubmitted("true")
                      }}
                      >
                        <label className="d-flex ">
                          <input type="radio" value={goPatients} name="goPatients" checked={goPatients == 1} />
                          <span className="ms-2">Go</span></label>
                      </div>
                    </div>

                    <div >

                      <div className="radio-btn ms-4" onClick={() => {
                        setGopatients(2);
                        setDoctorSubmitted("true")
                      }}
                      >
                        <label className="d-flex ">
                          <input type="radio" value={goPatients} name="goPatients" checked={goPatients == 2} />
                          <span className="ms-2"> No Go </span>   </label>
                      </div>
                    </div>

                    <div >

                      <div className="radio-btn ms-4" onClick={() => {
                        setGopatients(3);
                        setDoctorSubmitted("true")
                      }}
                      >
                        <label className="d-flex ">   <input type="radio" value={goPatients} name="goPatients" checked={goPatients == 3} />
                          <span className="ms-2"> Reshoot </span>   </label>
                      </div>
                    </div>



                    <p className="filter-heads">Assigned dentist</p>


                    <div className="doctor-filters-conntainer">
                      {doctors.map((value, id) => {
                        return (
                          <Row className="mx-1 mt-1" key={id}>
                            <Col md={1}>
                              {" "}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="doctorFilter"
                                value={value._id}
                                id={value._id}
                                onChange={handleDoctorFilterChange}
                                checked={doctorfilterinfo.includes(value._id)}
                              />
                            </Col>
                            <Col
                              md={10}
                              className="checkbox-text d-flex align-items-top"
                            >
                              {" "}
                              <label htmlFor={value._id}>
                                Dr. {value.name}
                              </label>
                            </Col>

                          </Row>

                        );
                      })}
                      {doctors.length < 1 && <>
                        <Row className="mx-1 mt-1 ">
                          <Col md={1} className="mt-1">
                            <input type="checkbox" id="sample" value="sample" />
                          </Col>
                          <Col
                            md={10}
                            className="checkbox-text d-flex align-items-top">

                            <Link to="/dentist" >
                              <p>Add dentists </p>
                            </Link>
                          </Col>

                        </Row>

                      </>
                      }
                    </div>

                  </div>
                </div>
                <div className="flex-grow-1 bg-white rounded-4">
                  <div className="d-flex justify-content-between w-5 ">
                    <div className="m-2 d-flex">
                      <button type="button" className="menu-button d-flex align-items-center justify-content-center" onClick={() => setFilters(!showFilters)}>
                        <IoMenu />
                      </button>

                      <Button className="add-user-btn ms-2" onClick={() => setSignUpForm(true)}>
                        <span className="">Add User</span>
                      </Button>
                    </div>


                    <div className="d-flex flex-row-reverse p-1  align-items-center me-4">
                      <Button disabled={currentpage == (totalpage == 0 ? currentpage : totalpage - 1)} onClick={handlenextpage}   >
                        <ArrowForwardIosIcon />
                      </Button>
                      <Button disabled={currentpage == 0} onClick={handlepreviouspage} >
                        <ArrowBackIosIcon />
                      </Button>
                      <div className="p-1 ">
                        {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}
                        -
                        {currentpage == totalpage - 1
                          ? filteredUsersCount
                          : (currentpage + 1) * 8}
                        <span className="mx-1">of</span>
                        {filteredUsersCount}
                      </div>

                      <select
                        className="form-btns-dashboard text-sans-bold mx-3"
                        value={sorting}
                        onChange={handlesorting}
                      >
                        <option value="" className="text-sans-bold ">
                          Sort By
                        </option>
                        <option value="Newest on top">Newest on top </option>
                        <option value="Oldest on top">Oldest on top</option>
                      </select>

                      {/* <CSVLink {...csvReport}>
                        <Button>   <FaDownload size="22px" className='mx-2 text-primary' />
                        </Button>
                      </CSVLink> */}

                    </div>
                  </div>
                  <div className="lists">
                    <div className="">
                      <PatientSummary />
                      {patientsLoading ? <SkeletonLoader /> :
                        users.length > 0 ? users.map((value) => {
                          return (
                            <User key={value._id} details={value} />
                          );
                        }) : <div className="d-flex justify-content-center my-5">
                          <img src="https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?w=740&t=st=1694862905~exp=1694863505~hmac=49cef82c3f87d5988e32f898b346f10a7b033895c4ee67fdfdb3f08e217c4f20" alt="" height="300px" />
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
          }

        </div >
      </Row>

      {assignedDentistPopup && (
        <UpdateDentistPopup
          userData={userData}
          assignedDentist={assignedDentist}
          popupclose={closeAssigndentistPopup}
        />
      )}

      {
        iSignUpFormOpen && <SignUpForm reload={() => { initialCall() }} popupclose={() => { setSignUpForm(false) }} />
      }

      {
        reload && <ReloadApp reload={reload} version={updatedVersion} />
      }

      {snackbar && (
        <SnackFire
          open={snackbar}
          onClose={() => { setsnackbar(false) }}
          position="top-right"
          timing={4000}
          type={type}
          message={snackbarmessage}
        />
      )}



    </Container>
  );

};

export default AlignerDashboard;
