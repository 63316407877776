import React, { useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
  loginAdmin,
} from "../../Services/AlignerService";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SnackFire from "../../UI/Snackbar/Snackbar";


const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [loader, setLoader] = useState(false)
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const [checkbox, setRememberMe] = useState(false)

  const initialValues = {
    email: "",
    password: "",
  }

  const validateSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),

  })

  const submitLoginForm = async (values) => {
    setLoader(true)
    await loginAdmin({ ...values, checkbox })
      .then(async (loginResponse) => {
        if (loginResponse) {
          store(loginResponse);
          setLoader(false)
        }
      })
      .catch(async (error) => {
        setLoader(false)
        setsnackbar(false);
        setsnackbarmessage(error.data.message);
        setsnackbar(true);
        settype("error");
      });
  }

  const store = (loginResponse) => {
    const { admin, token } = loginResponse.data.data
    const { app, app_url } = admin
    const xld = { app, app_url }
    localStorage.setItem("te", JSON.stringify(false))
    localStorage.setItem("authToken", loginResponse.headers.authorization);
    localStorage.setItem("xld", JSON.stringify(xld));
    localStorage.setItem("tlnv", JSON.stringify(token))

    if (app && app_url) {
      navigate("/aligner-dashboard")
    } else {
      setLoader(false)
      settype("error");
      setsnackbarmessage("User app haven't initiated yet. Please contact Toothlens team.")
      setsnackbar(true)
    }

  };
  const handleclose = () => {
    setsnackbar(false);
  };


  return (
    <Container fluid>
      <Row>
        <Col md={6} xs={12} className="signup_image">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <img className="img-fluid rounded" src={ToothScan} alt="Scan" />
          </div>
          <p className="image d-flex justify-content-center py-5 signup-footer">  Powered by Toothlens </p>
        </Col>
        <Col>
          <div className="bordker border-danger h-100 d-flex flex-column align-items-center justify-content-evenly">
            <div className="align-check-login-container">
              <Formik initialValues={initialValues} validationSchema={validateSchema} onSubmit={submitLoginForm}>
                <Form>
                  <Container>
                    <Row className="mt-3 ">
                      <Col className='d-flex justify-content-center'>
                        <div className='w-100'>
                          <label htmlFor="email" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                              Email
                            </span>
                          </label>
                          <Field type="email" id="email" name="email" className="form-input  grey-bg-color" placeholder="johnDow@gmail.com" />
                          <ErrorMessage name="email" component="span" className='text-danger ms-3' />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className='d-flex justify-content-center'>
                        <div className='w-100'>
                          <label htmlFor="password" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                              Password
                            </span>
                          </label>
                          <div className="position">
                            <Field type={showPassword ? "text" : "password"} id="password" name="password" className="form-input  grey-bg-color" placeholder="xxxxxx" />
                            <button type="button" className="p-1 pass-visible-icon bg-transparent border-0 " onClick={() => setshowPassword(!showPassword)}> {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</button>
                          </div>
                          <ErrorMessage name="password" component="span" className='text-danger ms-3' />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between px-2 mt-2 field-texts">
                          <div>
                            <input type="checkbox" id="remarks-check-box" className="form-check-input" onChange={(e) => setRememberMe(e.target.checked)} />
                            <label htmlFor="remarks-check-box" className="ms-1" style={{ fontSize: "14px" }}> Remember Me</label>
                          </div>
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-end align-items-center  mt-4">
                          <button type="submit" className="btn btn-primary form-btn border-0 rounded shadow w-100" disabled={loader} >
                            {loader ? <div className="text-center">
                              <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                <span className="sr-only"></span>
                              </div>
                            </div> : <span> LOG IN</span>} </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mt-4">
                          <span className="already-text">  Don't have an account. </span>
                          <span className="login-link-text">  <Link to="/signup/64233094abcc9054c50ae0e8"> Sign up</Link> </span>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Formik>
            </div>
            {snackbar && (
              <SnackFire
                open={snackbar}
                onClose={handleclose}
                position="top-right"
                timing={5000}
                type={type}
                message={snackbarmessage}
              />
            )}

          </div>
        </Col>
      </Row>
    </Container>

  );
};

export default Login;
