import React, { useEffect } from "react";
import "./thanks.css";
import Navbarthanks from "./navthanks";
import Container from "react-bootstrap/Container";
import { logoutUserService } from "../../Services/AlignerService";
const Thanks = () => {
  useEffect(()=>{
      logoutUserService()
  },[])
  return (
    <div>
      <Navbarthanks />
      <Container>
        <div className="d-flex w-5 h-5 thanks_height-100 justify-content-center align-items-center">
          <div className="display-1">Thanks for Signing up!</div>
          <div>
            <div>
              Your details are noted and we are working on the URL. We will get
              back to you within 48-72 hours. Keep checking your
              registered email for the further updates.
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Thanks;
