import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBinaryDataOfClinicImage } from '../Services/AlignerService'

const initialState = {
    fetched: false,
    officeLogo: ''
}

export const getAppLogo = createAsyncThunk('appDetails/getAppLogo', async (params) => {
    return await getBinaryDataOfClinicImage(params)
})

const appLogoSlice = createSlice({
    name: "appLogo",
    initialState,
    reducers: {
        clearLogo: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppLogo.fulfilled, (state, action) => {
                const binary = action.payload.data.binaryData.data
                const byteArray = new Uint8Array(binary)
                const blob = new Blob([byteArray], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                state.officeLogo = imageUrl
                state.fetched = true
            })
            .addCase(getAppLogo.rejected, (state) => {
                state.fetched = false
            })
    }
})

export default appLogoSlice.reducer
export const { clearLogo } = appLogoSlice.actions