import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import { addAlignerDoctor } from "../../Services/AlignerService";
import { FaUserMd } from "react-icons/fa";
import {
  roleList,
} from "../../Services/AlignerService";
import SnackFire from "../../UI/Snackbar/Snackbar";
import TokenExpired from "../TokenExpired";
import { getDoctors } from "../../Store/doctors";

const AddDentist = ({ change }) => {
  const app = JSON.parse(localStorage.getItem("xld")).app;
  const dispatch = useDispatch()
  const [roles, setroles] = useState("");
  const [loading, setloading] = useState(true);
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const handleclose = () => {
    setsnackbar(false);
  };
  const call = async () => {

    await roleList()
      .then((res) => {
        setroles(res.data.data);
        setloading(false);
      })
      .catch();
  };

  useEffect(() => {
    call();
  }, []);
  if (loading) {
    <div>Loading...</div>;
  }
  if (!loading) {
    return (
      <div>
        {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
        <Formik
          validate={(values) => {
            let errors = {};
            if (!values.name) {
              errors.name = " Name is required";
            }
            if (!values.email) {
              errors.email = "Email is required";
            }
            if (!values.phone_number) {
              errors.phone_number = "Phone number is equired"
            }
            if (!values.role) {
              errors.role = "Please do mention the role";
            }

            return errors;
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(" Name is required"),
            email: Yup.string().email('Invalid email').required('Email is required'),
            phone_number: Yup.string().required("Phone number is Required"),
          })}
          initialValues={{
            name: "",
            phone_number: "",
            email: "",
            role: roles.length > 0 ? roles[0]._id : null,
            // location: branches.length > 0 ? branches[0]._id : null,
            designation: "",
          }}
          onSubmit={(values) => {
            addAlignerDoctor({ app, is_active_doctor: true, ...values })
              .then((res) => {
                setsnackbarmessage(res.data.message);
                JSON.stringify(res.data.data) === "{}"
                  ? settype("error")
                  : settype("success");
                setsnackbar(true);
                dispatch(getDoctors({ app, doctorRole: "63d93e90dae984f675862ad4" }))
                if (JSON.stringify(res.data.data) != "{}") {
                  change();
                }
              })
              .catch((err) => {
                console.log(err)
                settype("error")
                setsnackbarmessage("something went wrong adding the dentist");
                setsnackbar(true);
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <div className="bg-white">

                  <div className="d-flex justify-content-center">
                    <FaUserMd size="22px" color="#0090FF" className="m-2" />
                    <p className="text-primary fs-5"> Add Member</p>
                  </div>

                  <div>
                    {" "}
                    <Col
                      md={12}
                      lg={12}
                      className="d-flex justify-content-center  mt-3"
                    >
                      <Col
                        md={12}
                        xs={12}
                        lg={12}
                        className="d-flex position mx-3 mt-2 "
                      >
                        <div>
                          <div className="d-flex w-5">
                            <Col>
                              <FormLabel
                                component="legend"
                                className="font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">
                                  {" "}
                                  Name*
                                </span>
                              </FormLabel>
                            </Col>
                          </div>

                          <div className="d-flex w-5 ">

                            <Col md={12} className="mt-1 px-2">
                              <input
                                type="text"
                                id="name"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="FirstName LastName"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </div>
                          <div className="mx-3">
                            {" "}
                            {errors.name && touched.name && (
                              <div className="text-danger mb-2 ml-2">
                                {errors.name}
                              </div>
                            )}
                          </div>
                          <Col>
                            <FormLabel
                              component="legend"
                              className="pt-1 font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">
                                Phone Number*
                              </span>
                            </FormLabel>
                          </Col>

                          <Col className="mt-1">
                            {" "}
                            <input
                              type="text"
                              id="phone_number"
                              className="form-input mb-3 grey-bg-color"
                              placeholder="phone_number"
                              value={values.phone_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                          <div className="mx-3">
                            {" "}
                            {errors.phone_number && touched.phone_number && (
                              <div className="text-danger mb-2 ml-2">
                                {errors.phone_number}
                              </div>
                            )}
                          </div>
                          <Col>
                            <FormLabel
                              component="legend"
                              className=" font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">Email*</span>
                            </FormLabel>
                          </Col>
                          <Col className="mt-1">
                            {" "}
                            <input
                              type="text"
                              id="email"
                              className="form-input mb-3 grey-bg-color"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="mx-3">
                              {" "}
                              {errors.email && touched.email && (
                                <div className="text-danger mb-2 ml-2">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </Col>



                          <Col>
                            <FormLabel
                              component="legend"
                              className=" font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">Role*</span>
                            </FormLabel>
                          </Col>

                          <Col className="mt-1">
                            <select
                              id="role"
                              className="form-input mb-3 grey-bg-color"
                              value={values.role}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ display: "block" }}
                            >
                              {roles.map((option) => {
                                return (
                                  <option key={option._id} value={option._id}>
                                    {" "}
                                    {option.designation}
                                  </option>
                                );
                              })}
                            </select>

                            <div className="mx-3">
                              {" "}
                              {errors.role && touched.role && (
                                <div className="text-danger mb-2 ml-2">
                                  {errors.role}
                                </div>
                              )}
                            </div>
                          </Col>


                          <Col>
                            <FormLabel
                              component="legend"
                              className="font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">
                                Designation
                              </span>
                            </FormLabel>
                          </Col>

                          <Col className="mt-1">
                            {" "}
                            <input
                              type="text"
                              id="designation"
                              className="form-input mb-3 grey-bg-color"
                              placeholder="designation"
                              value={values.designation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </div>

                        {/* </div> */}
                      </Col>
                    </Col>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <Button className="form-btn" type="submit">
                      <span className="btn-text">Add Member</span>
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>{" "}
      </div>
    );
  }
};

export default AddDentist;
