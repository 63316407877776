import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getAllAlignerDoctors } from "../Services/AlignerService"


const initialState = {
    isLoading: false,
    doctors: [],
    error: '',
    fetched: false
}

export const getDoctors = createAsyncThunk('doctors/getdoctors', async (obj) => {
    return await getAllAlignerDoctors(obj)
})


const doctorsSlice = createSlice({
    name: "doctors",
    initialState,
    reducers: {
        clearDoctors: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDoctors.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDoctors.fulfilled, (state, action) => {
                state.isLoading = false;
                state.doctors = action.payload.data.allDoctors;
                state.fetched = true
            })
            .addCase(getDoctors.rejected, (state, action) => {
                console.log(action.payload)
                state.isLoading = false;
                state.error = action.error.message;
                state.fetched = false;
            })
    }

})

export default doctorsSlice.reducer
export const { clearDoctors } = doctorsSlice.actions