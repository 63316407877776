import React, { useRef } from "react";
import "./baa.css";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyAggrementSignService } from "../../Services/AlignerService";

const TermsAndCondition = () => {
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = {
    email: location.state,
  };
  const onSubmit = () => {
    verifyAggrementSignService(data)
      .then((response) => {
        if (response) {
          navigate("/login");
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div className="model">
      <div>
        <div className="model-container  m-5 p-5">
          <div>Toothlens Manager TERMS OF USE</div>
          <div>Last Updated: August 15, 2022 </div>
          <br></br>
          <div className="">
            Toothlens’s Manager described in these Terms of Use (“Terms”) is
            provided by Toothlens (referred to as “Toothlens,” “us,” “our,” or
            “we”), and is a web-based software developed, designed and owned by
            Toothlens pursuant to which dental professionals and dental
            businesses use for marketing optimisation, lead generation and
            remote monitoring and patient management activities (collectively,
            the “Service”).
            <br></br>
            <br></br>
            Toothlens does not control the content of any information entered
            into the Service by a Dental office, Dental support organisation,
            Provider, Subscriber, Third Party Source, or any other party.
            Toothlens does not control the conduct of any Dental Office, Dental
            Support Organisation, Provider or Subscriber. By creating an
            account, or accessing and using the technology used by Toothlens to
            provide the Service, including the Toothlens manager software, or
            any Toothlens mobile application, You are entering into a binding
            legal agreement with Toothlens.
            <br></br>
            <br></br>
            If you are accessing and using the software on behalf of a company,
            you warrant and represent that you are authorized to bind the
            company and that you are accepting the Agreement on the company’s
            behalf. When used in the Agreement, “You” or “Your” will refer to
            you as an individual, or to Your company if you are accepting the
            Agreement on behalf of a company. If You do not agree with all the
            provisions in these Terms, You must not access or use the Service.
            PLEASE READ THIS ENTIRE DOCUMENT, AND THE DOCUMENTS AT THE
            HYPERLINKS BELOW, AND PRINT COPIES FOR YOUR RECORDS. If You are
            asked to click Your agreement to specific terms and conditions to
            access certain functions on any portions of the Software (each a
            “Site Agreement”), the terms in the Site Agreement will govern over
            any inconsistencies with these Terms, but only for the specific
            Software Site or Toothlens Apps function described in that Site
            Agreement. Remember that Your use of the Software is at all times
            also subject to, to the extent applicable, the Toothlens Business
            Associate Agreement (the “Toothlens BAA”), and all applicable
            Toothlens App agreements, which are collectively incorporated into
            these Terms. These Terms, the Toothlens App agreements, and any
            applicable Site Agreement(s), are referred to collectively as the
            “Agreement”. YOU AGREE TO BE BOUND BY THE AGREEMENT, AND YOU
            REPRESENT AND WARRANT THAT: A) YOU ARE AT LEAST 18 YEARS OF AGE; B)
            YOU HAVE READ AND UNDERSTAND THE ENTIRE AGREEMENT; C) YOU ARE NOT
            RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS
            EXPRESSLY SET FORTH IN THE AGREEMENT; AND D) TO THE EXTENT AN
            INDIVIDUAL IS EXECUTING THIS ON BEHALF OF A COMPANY, SUCH INDIVIDUAL
            HAS THE POWER AND AUTHORITY TO EXECUTE THESE TERMS AND CONDITIONS ON
            BEHALF OF THE COMPANY. WE MAY REVISE AND UPDATE THESE TERMS AT ANY
            TIME, AND THE OTHER DOCUMENTS INCORPORATED INTO THE AGREEMENT, AND
            THE UPDATES SHALL BE PROVIDED TO YOU WHEN YOU ENTER THE SOFTWARE FOR
            THE FIRST TIME AFTER SUCH CHANGES. YOUR CONTINUED USE OF THE
            SOFTWARE AFTER ANY SUCH CHANGES CONSITUTES YOUR ACCEPTANCE OF THE
            NEW TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO ABIDE BY SUCH
            TERMS, DO NOT USE, OR CONTINUE TO USE, THE SOFTWARE.
            <br></br>
            <br></br>
            1. Definitions In addition to terms defined in the text of these
            Terms, the following terms shall have the meanings set forth below:
            “Intellectual Property” includes, without limitation, all intangible
            legal rights or interests evidenced by or embodied in: (a) any idea,
            design, concept, technique, invention, discovery, enhancement or
            improvement, regardless of patentability, but including patents,
            patent applications, trade secrets, and know-how; (b) any work of
            authorship, regardless of copyrightability, including copyrights and
            any moral rights recognized by law; (c) any trademark, trade name or
            service mark; and (d) any other intellectual property, proprietary
            or similar rights, including all goodwill pertaining thereto and in
            each case, on a worldwide basis. “Participation” means a Provider’s
            or Subscriber’s use of the Platform for one or more services.
            “Participation File(s)” means the electronic file(s) containing the
            Participation Information. The Participation File format is not
            specific to any Subscriber formatting requirements. “Participation
            Information” means the claims information, credentialing
            information, authorizations, contracting information, practice
            management information, Practitioner Information, and all other
            related information provided by a Provider, Subscribers, or by other
            Third Party Sources engaged by Toothlens, and which is contained in
            Participation Files and posted on the Platform. “Practitioner” means
            a dentist, dental specialist, dental hygienist, or other healthcare
            provider that, as required, is duly licensed to practice by the
            State and is performing within the scope of his/her practice as
            defined by law in the State in which he/she practices and is
            rendering patient services. “Provider” means an individual, group,
            organization, entity, or facility who provide dental services by
            related Practitioners who hold licenses and actively engage in a
            dental practice under one tax identification number. “Practitioner
            Information” means the information that the Provider, on behalf of a
            Practitioner, inputs, uploads or updates in the Platform, which
            includes, but is not limited to, provider directory information,
            such as a Practitioner’s name, status, birthday, gender, NPI,
            provider specialties, associated office locations and office hours,
            a Practitioner’s Credentialing Information, such as education,
            residency information, licensing, board certifications, and hospital
            affiliations, other provider professional information and practice
            specific information, insurance information, provider billing
            information, and other provider data related to the provider’s
            practice. “Subscriber” means a federal or state government agency, a
            managed care organization, an insurance carrier, a healthcare
            network, a hospital, employer, a third party administrator, or any
            other applicable third party that has entered into an Agreement to
            use the Platform. “Third Party Source(s)” includes, but is not
            limited to, individuals, agencies, dental groups responsible for
            credentials verification, corporations, companies, employers, former
            employers, hospitals, health plans, health maintenance
            organizations, managed care organizations, law enforcement or
            licensing agencies, insurance companies, educational and other
            institutions, military services, medical credentialing and
            accreditation agencies, professional medical societies, the
            Federation of State Medical Boards, the American Association of
            Dental Boards, the National Practitioner Data Bank, and the Health
            Care Integrity and Protection Data Bank.
            <br></br>
            <br></br>
            2. Toothlens ONLY A TECHNOLOGY PLATFORM The Platform is an online
            and mobile platform made available by Toothlens to provide the
            Service. Except as specifically provided for in these Terms,
            Toothlens does not perform any other services via the Platform,
            including without limitation any validation or verification of
            credentialing information, or other data, other services related to
            Practitioners, Providers, Provider billing, payor payments for
            healthcare services, participation decisions, or otherwise. Except
            as specifically provided for in the Terms, Toothlens’s
            responsibilities are limited to providing access to the Platform
            under these Terms and any Site Agreement. Toothlens assumes no
            responsibility for any Platform user’s compliance with any
            agreements with or duties to You or to third parties, or any
            compliance with any laws, rules and regulations. You acknowledge and
            agree that You, not Toothlens, will be responsible for performing
            any obligations of any such agreements, that Toothlens is not a
            party to such agreements. Toothlens DISCLAIMS ALL LIABILITY ARISING
            FROM OR RELATED TO ANY SUCH AGREEMENTS. Toothlens CANNOT AND DOES
            NOT CONTROL ANY THIRD PARTY CONTENT (INCLUDING WITHOUT LIMITATION
            ANY INFORMATION POSTED ON THE PLATFORM), OR THE CONDITION, LEGALITY
            OR SUITABILITY OF ANY INFORMATION POSTED ON THE PLATFORM. By using
            the Platform, YOU AGREE Toothlens IS NOT LIABLE TO YOU FOR THE ACTS
            OR OMISSIONS OF PLATFORM USERS, PROVIDERS, SUBSCRIBERS, OR OTHER
            THIRD PARTIES. In addition, Toothlens shall not be liable for any
            delay or failure to perform any obligation, if such delay or failure
            is caused by, or if such performance is made impractical or
            commercially unreasonable by, fire, hurricane, flood, other severe
            weather events, or other act of God, labor dispute or strike,
            terrorism, war or civil commotion, governmental action, equipment
            breakdown, inability to obtain the necessary labor, materials or
            equipment, or any other cause beyond the reasonable control of
            Toothlens. 3. OVERVIEW AND OWNERSHIP OF CONTENT AND DATA The
            Platform contains postings, text, images, messages, photographs,
            videos, audio files, graphics, artwork, data, databases, interfaces,
            documentation, interactive tools, metrics, processes, procedures,
            models, forms, tools, templates, calculations, reports, flowcharts,
            and other information and materials for use online or download by
            users of the Platform, as made available by Toothlens and our
            advertisers, sponsors, licensors, suppliers, and other content
            contributors (“Content Contributors”). Portions of the Platform
            authored by Toothlens or its authorized agents, contractors, and
            employees are referred to as “Toothlens Content.” Portions of the
            Platform authored by other Content Contributors are referred to as
            “Third Party Content.” Toothlens Content and Third Party Content
            will be collectively referred to as the “Platform Content." Please
            be aware that we may allow third parties to post widgets to the
            Platform Site to deliver Third Party Content (“Widgets”). These
            Widgets are controlled by Third Party Content providers (“Widget
            Providers”). We do not endorse and are not responsible or liable for
            any Third Party Content, advertising, products, or other materials
            on or available through such Widgets. The use of these Widgets is
            governed by the applicable Widget Providers’ separate terms of use.
            Although Toothlens attempts to ensure the accurateness of the
            Platform Content, it makes no guarantees whatsoever as to its
            correctness or accuracy. It is possible that the Platform Content
            could include inaccuracies or errors. In the event that an
            inaccuracy arises, please inform Toothlens so that it can be
            addressed. Information contained on the Platform may be changed or
            updated without notice. A. Use Rights; Ownership and Use of Content
            You may only access and use the Platform for your non-exclusive use
            so long as you comply with the Agreement. You may only use the
            Platform and the Platform Content for its intended purposes for
            which it is made available to you by Toothlens. The Platform
            technology, underlying ideas, data, databases, and Platform Content
            are protected by federal and international copyright and
            intellectual property laws and are the property of Toothlens and
            applicable Content Contributors. No portion of the Platform Content
            may be reprinted, republished, modified, or reproduced. You
            acknowledge that the Platform and its contents, and any and all
            enhancements, modifications, additions or new releases of or to the
            Platform, contain confidential information of, are trade secrets of,
            and are proprietary to Toothlens and that title to such is and shall
            remain in Toothlens. All applicable rights to patents, copyrights,
            trademarks and trade secrets are and shall remain in Toothlens.
            Other than as specifically permitted in the Agreement, or as
            pre-approved in each instance in writing by Toothlens, You are not
            permitted to use or reference in any manner Toothlens’s, our
            affiliates’, or their respective licensors’ or suppliers’ company
            names, logos, products and service names, trademarks, service marks,
            trade dress, copyrights or other indicia of ownership, alone and in
            combination with other letters, punctuation, words, symbols, or
            designs (the “Toothlens Marks”) for any commercial purposes, unless
            otherwise specifically agreed in writing. You will not try to
            register or otherwise use or claim ownership in any of the Toothlens
            Marks, alone or in combination with other letters, punctuation,
            words, symbols, or designs, or in any confusingly similar mark, name
            or title, for any goods and services, and to the extent You do, You
            hereby assign to Toothlens all worldwide right, title, and interest
            in and to any common law rights, applications, and registrations
            relating to Toothlens Marks, and all related goodwill, without any
            further compensation owed to You. You acknowledge that users of the
            Platform may be required to register and provide us with certain
            information (including personal information). We may also collect or
            obtain through third parties data arising from the use of the
            Platform, which may be collected using cookies, pixel tags, web
            beacons, clear gifs, Widgets and other tracking technologies.
            “Toothlens User Data” means all such Platform user data and
            information collected, generated, derived or obtained by us or by
            the Platform. Toothlens User Data will be collected and handled in
            accordance with the Toothlens Privacy Policy and, to the extent
            applicable, the Toothlens BAA. B. Third Party Content Certain
            trademarks, service marks and logos used in the Platform are the
            trademarks, service marks or logos of third parties. All Third Party
            Content is owned or controlled by the applicable Content
            Contributors. It is possible that the Third Party Content could
            include inaccuracies or errors. Toothlens is not responsible for any
            Third Party Content, and the applicable Content Contributors are
            solely responsible for their respective Third Party Content. Third
            Party Content may be protected by federal and international
            copyright, trademark, or other laws, and Your right to reprint,
            republish, modify, reproduce, or distribute Third Party Content may
            be limited accordingly. C. Your Feedback Any comments, feedback,
            information, or materials regarding the Platform or our products,
            services or technologies (collectively, “Feedback”) that You submit
            to us shall become Toothlens’s property. By submitting Your Feedback
            to us, You agree to assign, and hereby irrevocably assign to us, all
            right, title, and interest in and to the Feedback and all copyrights
            and other intellectual property rights embodied in such Feedback on
            a worldwide basis without any compensation or obligation to You.
            Toothlens shall be free to use Your Feedback on an unrestricted
            basis. You hereby assign and waive, as set forth in applicable laws,
            any moral rights that You may have in or to the Feedback. 4.
            PERMITTED USES A. Usage Restrictions. Except as otherwise provided
            under the terms of the Agreement, You shall not: (a) make the
            Platform, the Platform Content, Your Participation Information, or
            any Participation Files available to any third party, including
            without limitation any Subscriber or other Provider; (b)
            commercially exploit, or use outside of the Platform for your own
            use or any competitive advantage, any Platform Content or any
            Participation Information; (c) sell, resell, license, distribute,
            loan, outsource or otherwise transfer any Platform Content,
            Participation Files or Your Participation Information to any third
            party; (d) modify or alter any Participation Files, or any
            Participation Information other than Your Participation Information;
            or (e) use the Platform, Participation Files or Your Participation
            Information except as expressly allowed under the Agreement. B. Our
            Ownership. You shall not, by virtue of the Agreement or otherwise,
            acquire any rights whatsoever in the Platform, aside from the
            limited access rights granted herein, and You hereby expressly
            disclaim any other rights therein. As between the parties, Toothlens
            shall have and retain all right, title and interest in and to the
            Platform, and all Intellectual Property comprising the foregoing, as
            well as any modifications, enhancements, revisions, updates and
            upgrades made thereto or derivative works incorporating any of the
            foregoing. 5. USER CONTENT A. Your Content. All of Your
            Participation Information, to the extent applicable, and any other
            information, postings, text, data, files, and other materials You
            post, transmit through, or link to the Platform (collectively, “Your
            Content”) is Your sole responsibility. YOU ARE RESPONSIBLE FOR THE
            COMPLETENESS, TRUTHFULNESS, ACCURACY, AND RELIABILITY OF YOUR
            CONTENT. YOU AGREE THAT YOU WILL MONITOR YOUR CONTENT AND MAKE ALL
            TIMELY UPDATES TO YOUR CONTENT TO MAINTAIN COMPLIANCE WITH THIS
            SECTION 5(A). YOU AGREE THAT YOU WILL EVALUATE AND BEAR ALL RISK
            RELATED TO YOUR CONTENT, AND Toothlens HAS NO RESPONSIBILITY OR
            LIABILITY FOR YOUR CONTENT. THE RESULTS OF ANY ACTIONS YOU TAKE
            BASED ON YOUR CONTENT OR OTHER CONTENT YOU FIND ON THE PLATFORM ARE
            SOLELY YOUR RESPONSIBILITY. Under no circumstances will Toothlens be
            liable in any way for Your Content or for any loss or damage of any
            kind resulting from Your Content. B. Your Content License. As
            between us, You shall have and retain all right, title and interest
            in and to Your Content, including, to the extent applicable, any
            Practitioner Information. By uploading, transmitting, or posting
            Your Content, You grant to us an irrevocable, perpetual, unlimited,
            non-exclusive, worldwide, fully-paid, sublicensable and transferable
            license to view, use, copy, print, reproduce, adapt, modify, edit,
            post, process, store, display, disclose, license, distribute,
            transfer, transmit, and otherwise fully exploit Your Content in
            whole or in part in any and all media or distribution methods (now
            known or later developed), without any requirement of compensation
            to You or other obligations by Toothlens. You represent and warrant
            that You have all the rights, power and authority necessary to grant
            the rights granted herein to Your Content. To the extent You are a
            Provider, You represent and warrant that you have all of the
            necessary rights, power and authority to upload any Practitioner
            Information. Toothlens shall own all rights to any derivatives,
            modifications, enhancements, or improvements made by Toothlens to
            Your Content, and shall have no obligation to provide You with
            copies of any such modifications. However, Toothlens does not claim
            any ownership rights in any of Your Content and nothing in the
            Agreement will be deemed to restrict any rights that You may have to
            use and exploit any of Your Content. C. Monitoring and Disclosure.
            We do not pre-screen or approve Your Content and we have no
            obligation to monitor or verify Your Content. However, we reserve
            the right to review, modify, monitor, refuse to post, or delete any
            of Your Content at our sole discretion. We reserve the right at all
            times and in our sole discretion to disclose any information we
            believe necessary to satisfy any law, regulation, legal process, or
            governmental request, for investigation, risk assessment, security,
            or fraud prevention or detection purposes, or to facilitate Your
            interaction with or a request You placed through the Platform. D.
            Your Sole Responsibility. Participation Information in the Platform
            has not had primary source verification. You are solely responsible
            for Your actions relating to any of Your Participation Information
            or Participation Files obtained by You from the Platform including,
            but not limited to, protecting the confidentiality and data security
            of any individually identifiable information contained in any such
            Participation Information or Participation Files. 6. YOUR USER
            ACCOUNT A. Your Account Only those authorized individuals to or for
            whom we have supplied user identifications and passwords (“Platform
            User ID and Password”), may access and use the Platform (“Authorized
            Users”). You agree to maintain accurate and complete contact
            information, including office location, state in which you practice,
            and your state in residency, in connection with accounts, and to
            update this information promptly in the event it changes. In
            addition, each individual using the Platform is the only person
            authorized to access and use his or her account. Authorized Users
            may not give their usernames, passwords, or any other account
            information to a third party. You must immediately notify us of any
            known or suspected unauthorized use of an account or any known or
            suspected breach of security, including, but not limited to loss,
            theft, or unauthorized disclosure of passwords. YOU ARE RESPONSIBLE
            FOR ALL ACTIVITY ON YOUR ACCOUNTS, WHETHER OR NOT THE ACTIONS ARE BY
            YOUR AUTHORIZED USERS. Any fraudulent, misleading, abusive, or
            illegal activity may be grounds for termination of all related
            accounts at our sole discretion and we may refer You to appropriate
            law enforcement agencies. B. Your Responsibilities You understand
            and agree that Your relationship with Toothlens is limited to being
            a user of the Platform, and neither You nor any other user is an
            employee, agent, contractor, joint venture, or partner of Toothlens
            for any reason. Each user of the Platform acts exclusively on their
            own behalf and for their own benefit, and not on behalf of or for
            the benefit of Toothlens or any other user. You will not (i) use the
            Content in the Platform to develop or sell products or services
            similar to Toothlens’s, (ii) recruit or otherwise solicit any other
            user with respect to services that are competitive to Toothlens’s,
            or (iii) access the Platform to monitor its availability,
            performance, or functionality, or for any benchmarking or
            competitive purposes. You may not be granted access or use the
            Platform if You offer competitive services. In addition, You will
            not: (1) Delete, hack, or attempt to change or alter any content or
            notices; (2) Use or introduce into the Platform any device,
            software, or routine intended to damage, bypass, modify, interfere
            with, erase, or permit unauthorized access to the Platform, servers,
            or networks connected to the Platform, crack passwords or security
            encryption codes, circumvent authentication technology, or take any
            other action that interferes with any use of the Platform; (3) Use
            any automatic or manual device or process to harvest or compile
            information from the Platform for any reason; (4) Copy, modify,
            create derivative works, reverse engineer, decompile, disassemble,
            or otherwise attempt to learn the source code, structure, or ideas
            upon which the Platform is based; (5) Introduce into the Platform
            any software routine designed to disable a computer program
            automatically, with the passage of time, or under the positive
            control of an unauthorized person; (6) Remove or disable any
            cookies, widgets, or other tracking mechanisms in the Platform, or
            otherwise interfere with the collection of data arising from use of
            the Platform; (7) Use any content made available through the
            Platform in any manner that misappropriates any trade secret or
            infringes any intellectual property rights, rights of publicity, or
            other proprietary right of any party; (8) Collect, store or use
            personal data about other users, or otherwise attempt to access any
            other person’s information; (9) Decrypt, transfer, frame, display,
            or translate any part of the Platform; (10) Post, upload, or
            transmit any of content that is or encourages unlawful, harmful,
            threatening, abusive, harassing, bullying, defamatory, vulgar,
            obscene, pornographic, profane, indecent, sexually explicit, or
            hateful actions, or that is invasive of another’s privacy, or
            intended to offend any person based on a person’s race, ethnic
            heritage, national origin, sex, sexual orientation or preference,
            age, physical or mental illness or disability, marital status,
            employment status, housing status, or religion; (11) Impersonate any
            person or entity, including, but not limited to, a Toothlens
            employee or representative; (12) “Stalk,” harass, or threaten to
            invade the privacy of or harm another user of the Platform, or any
            other person, or otherwise use the Platform to create a risk of
            harm, loss, emotional distress, or physical or mental injury to any
            person or animal; (13) Breach any duty toward or rights of any
            person or entity (including, without limitation, rights of publicity
            or privacy), or otherwise take any action resulting in any consumer
            fraud, product liability, tort, breach of contract, injury, damage
            or harm of any kind to any person or entity; (14) Send, facilitate,
            or promote illegal games, contests, spam, surveys, unsolicited
            advertising or promotional materials, pyramid schemes or chain
            letters; (15) Disseminate malware, spyware, adware, viruses, Trojan
            horses, trap doors, worms, time bombs, cancelbots, corrupted files
            or any other similar software, files, or programs that may (i)
            damage or adversely affect the operation of the software, hardware
            or systems of Toothlens or any user, or (ii) interfere with,
            intercept or expropriate any personal information; (16) Interfere
            with or override the delivery or display of any advertising; (17)
            Use the Platform in disregard of (i) any known adverse consequences
            or (ii) warning message; (18) Create or attempt to create multiple
            user accounts on the Platform, except in accordance with the terms
            and conditions of the Platform; or (19) Use or access the Platform
            after termination of these Terms or any applicable Site Agreement.
            C. Pricing and Payment In exchange for use of certain Platform
            services, You agree to pay the fees set forth and forming an
            integral part of these Terms. Fees will be paid by credit card or
            wire trasfer through Toothlens’s third-party payment vendor at the
            time of purchase or, at the discretion of Toothlens, You will be
            invoiced for such fees. All invoices and payment will be in U.S.
            dollars, and if payment is by invoice, the invoice will be sent to
            Your designated email address. The fees shall not be inclusive of
            any taxes, levies, duties or similar governmental assessments or any
            nature, including, for example, value-added, sales, use or
            withholding taxes, assessable by any jurisdiction whatsoever
            (collectively, “Taxes”). You are responsible for payment all Taxes
            associated with the fees hereunder, other than Toothlens’s U.S.
            federal and state income taxes. If Toothlens has the legal
            obligation to pay or collect such Taxes for which You are
            responsible, Toothlens will invoice You and You will pay that amount
            unless You provide Toothlens with a valid tax exemption certificate
            authorized by the appropriate taxing authority. You agree to pay all
            fees invoiced by Toothlens within seven (7) days after the date of
            invoice. Payments not received by the due date shall bear interest
            at a rate equal to the lesser of one and one-half percent (1 ½%) per
            month or the maximum rate allowed by law. The Subscriber Service Fee
            Schedule is subject to change based on Toothlens providing notice to
            You of such changes thirty (30) days prior to such changes taking
            effect. D. Use Restrictions; Deactivation of Your Account We may
            modify, suspend, or close any account, or otherwise stop providing
            the Platform, in whole or in part, at any time and for any reason,
            including failure to pay. We will not be liable to You or any third
            party for suspension or termination of any Platform or accounts. You
            will not be able to access any information in any account once
            access has been suspended or terminated, or a Platform Site or
            Toothlens App has been shut down. Termination of the use of the
            Platform will not relieve You of Your obligations to pay any amounts
            then due to us, and will not entitle You to a refund of any amounts
            paid under the Agreement. You agree that Toothlens or our licensors
            are debtors in any bankruptcy proceeding, that this Agreement is an
            “Executory Contract”, and that Toothlens and our licensors (as
            applicable) are each a “licensor of a right to intellectual
            property” as those terms are defined in 11U.S.C.§365(n), as amended
            from time to time. YOU ARE RESPONSIBLE FOR MAKING SURE YOUR CONTENT
            AND OTHER INFORMATION YOU DESIRE IS PROPERLY BACKED UP SO YOU HAVE
            ACCESS TO IT IN THE EVENT OF LOSS, CORRUPTION, OR CESSATION OF ANY
            PLATFORM SITES OR Toothlens APPS, OR TERMINATION OR SUSPENSION OF
            ANY ACCOUNT. UNLESS WE AGREE OTHERWISE IN A SITE AGREEMENT, WE DO
            NOT EXPORT YOUR CONTENT, YOUR INFORMATION, OR OTHER DATA TO YOU
            DURING YOUR USE OF THE PLATFORM, OR PROVIDE YOUR CONTENT,
            INFORMATION, OR OTHER DATA TO YOU WHEN ANY ACCOUNT IS CLOSED. 7.
            TERMINATION A. Termination. If you violate applicable laws or the
            Agreement, you are immediately prohibited from further use of the
            Platform, and we may restrict your access to the Platform. Toothlens
            may suspend or terminate the Platform, in whole or in part, at any
            time in its sole discretion for any reason. Toothlens shall not be
            liable to you or anyone else for any damages arising from or related
            to Toothlens’s suspension or termination of your access to the
            Platform, or in the event Toothlens modifies, discontinues or
            restricts the availability of the Platform (in whole or in part). B.
            Effect of Termination. Upon termination of the Agreement for any
            reason, Your rights to access and use the Platform shall immediately
            and automatically terminate, and You shall cease all access and use
            of the Platform and any of the Intellectual Property related
            thereto. This termination shall not release Toothlens or You from
            obligations under the Agreement that accrued prior to the date of
            termination or from those obligations which, by their terms, survive
            termination. To the extent You are current on all fees owed to
            Toothlens for use of the Platform accruing through the date of
            termination, upon Toothlens’s termination of the Agreement, at Your
            request Toothlens will use commercially reasonable efforts to
            transfer Your Content to You in an agreed-upon electronic format. 9.
            MOBILE DEVICES Your contract with Your mobile network provider
            (“Mobile Provider”) will continue to apply when accessing or using
            the Toothlens Apps on Your mobile device (“Mobile Device”). You
            understand that Your Mobile Provider may charge You fees for Your
            use of its network while accessing or using the Toothlens Apps, for
            data downloading, email, text messages, for roaming, and other
            Mobile Provider or third party charges, and that YOU ARE SOLELY
            RESPONSIBLE FOR SUCH FEES AND CHARGES. You understand that the
            Toothlens Apps are provided over the Internet and mobile networks,
            so the quality and availability of the Toothlens Apps may be
            affected by factors outside our control. We do not guarantee that
            the Toothlens Apps will be compatible or operate with Your Mobile
            Provider’s service plans, with any particular mobile device, or
            other piece of hardware, software, equipment, or device You install
            on or use with Your Mobile Device. We are not responsible if the
            Toothlens Apps are unavailable or if You cannot download or access
            the content on the Toothlens Apps, for any compatibility or
            interoperability issues, or for any communication system failure
            which may result in the Toothlens Apps being unavailable. You will
            not, nor allow any Authorized Users or any third parties on Your
            behalf to: (1) Install, use or permit the Toothlens Apps to exist on
            more than one Mobile Device at a time, or on any other Mobile Device
            or computer, other than by means of separate downloads of the Mobile
            Toothlens Apps by Your Authorized Users, each of which is subject to
            a separate license; (2) Distribute or link the Toothlens Apps to
            multiple Mobile Devices or other services; or (3) Make the Toothlens
            Apps available over a network or other environment permitting access
            or use by multiple Mobile Devices or users at the same time.
            Toothlens may offer functionality in the Toothlens Apps in which
            Authorized Users may store their log-in credentials on their Mobile
            Devices, so that they can be automatically logged in each time they
            access the Toothlens Apps. If someone else obtains access to an
            Authorized User’s Mobile Device (e.g., through theft), the automatic
            log-in feature will enable that person to have access to that
            Authorized User’s Toothlens Apps account. Therefore, in the event an
            Authorized User’s Mobile Device is lost or stolen, it is their
            responsibility to contact their wireless carrier immediately to
            prevent the unauthorized use of the Toothlens Apps. YOU ACCEPT
            RESPONSIBILITY FOR ANY Toothlens DAMAGES RESULTING FROM SUCH
            UNAUTHORIZED ACCESS TO THE Toothlens APPS. For any Toothlens Apps
            accessed through or downloaded from a third party, such as the Apple
            App Store or other mobile application provider (“App Store
            Provider”), You acknowledge and agree that any terms to which You
            agree with the App Store Provider are between You and the App Store
            Provider, and Toothlens is not a party to any such terms. Toothlens
            DISCLAIMS ANY LIABILITY FOR THE ACTIONS OF ANY APP STORE PROVIDER.
            10. CONFIDENTIALITY You must keep Confidential Information secret.
            “Toothlens Confidential Information” means all nonpublic information
            concerning the business, technology, products, specifications,
            services, databases, pricing, procedures, and strategies of
            Toothlens, all Toothlens User Data, Your Feedback, and any other
            information labelled by Toothlens as “confidential” or that a
            reasonable person would understand to be confidential or
            proprietary. 11. YOUR INDEMNIFICATION OF Toothlens YOU SHALL
            INDEMNIFY, DEFEND, AND HOLD HARMLESS Toothlens, ITS AFFILIATES,
            SUBCONTRACTORS, SUPPLIERS, AND ASSIGNS, AND THEIR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS, AND EACH OF THEM, FROM AND AGAINST
            ANY AND ALL LOSSES, COSTS, EXPENSES, DAMAGES, LIABILITIES, CLAIMS,
            SUITS, DEMANDS, JUDGMENTS, AND CAUSES OF ACTION OF ANY NATURE,
            INCLUDING, BUT NOT LIMITED TO, REASONABLE ATTORNEYS’ FEES AND ALL
            OTHER COSTS AND EXPENSES, ARISING OUT OF OR RELATING (DIRECTLY OR
            INDIRECTLY) TO THE USE OF THE PLATFORM AND THE SERVICES. EXAMPLES
            COULD INCLUDE, BUT ARE NOT LIMITED TO: (A) YOUR OR YOUR AFFILIATES’
            FRAUD, NEGLIGENCE OR MISCONDUCT, OR ALLEGED VIOLATION OF ANY LAW OR
            REGULATION; (B) YOUR FAILURE TO COMPLY WITH ANY TERM OR CONDITION
            (INCLUDING, BUT NOT LIMITED TO, ALL WARRANTIES AND REPRESENTATIONS)
            OF THE AGREEMENT; (C) ALLEGED OR ACTUAL INFRINGEMENT BY YOUR CONTENT
            OF ANY INTELLECTUAL PROPERTY, PRIVACY, OR OTHER RIGHT OF ANY THIRD
            PARTY; (D) MISREPRESENTATION BY YOU OR YOUR AFFILIATES; (E) ANY
            BREACHES BY YOU OR YOUR AFFILIATES OF ANY DUTY TOWARD OR RIGHTS OF
            ANY PERSON OR ENTITY (INCLUDING, WITHOUT LIMITATION, RIGHTS OF
            PUBLICITY OR PRIVACY), OR THAT HAS OTHERWISE RESULTED IN ANY INJURY,
            DAMAGE OR HARM OF ANY KIND TO ANY PERSON OR ENTITY; (F) ANY CLAIM
            RELATED TO YOUR PRODUCTS OR SERVICES; (G) ANY STATEMENTS BY YOU OR
            YOUR AFFILIATES RELATING TO Toothlens OR THE PLATFORM; OR (H) ANY
            AGREEMENT OR OTHER AGREEMENTS OR ARRANGEMENTS BETWEEN YOU AND ANY
            OTHER USER OF THE PLATFORM, PROVIDER GROUP, PAYOR, GOVERNMENT
            AGENCY, OR THIRD PARTY. 12. NO WARRANTY THE SERVICE IS PROVIDED “AS
            IS” AND “AS AVAILABLE” EXCEPT TO THE EXTENT SPECIFICALLY PROVIDED
            OTHERWISE IN A SITE AGREEMENT. THE PLATFORM, INCLUDING ANY PLATFORM
            CONTENT OR INFORMATION CONTAINED IN THE PLATFORM AND ANY RELATED
            SERVICES, ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH NO
            REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
            NONINFRINGEMENT, OR ANY WARRANTY ARISING FROM A COURSE OF DEALING OR
            USAGE IN TRADE, WHICH ARE DISCLAIMED IN THEIR ENTIRETY. STATEMENTS
            OUTSIDE THE AGREEMENT, INCLUDING, WITHOUT LIMITATION, STATEMENTS
            REGARDING CAPACITY, SUITABILITY FOR USE OR PERFORMANCE, WHETHER MADE
            BY OUR EMPLOYEES OR OTHERWISE, ARE NOT WARRANTIES OR PROMISES BY US,
            AND WE HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY SUCH STATEMENTS.
            Toothlens DOES NOT WARRANT OR REPRESENT THAT ANY COMPANY OR
            INDIVIDUAL WILL ENTER INTO ANY TRANSACTION AS A RESULT OF USING THE
            PLATFORM, THE QUALITY OF ANY PARTICIPATION INFORMATION OR ANY
            SERVICES, THAT YOU WILL ACHIEVE ANY SPECIFIC RESULTS OR EARN ANY
            MONIES WHATSOEVER, OR THAT ANY USER WILL COMPLY WITH ANY LAWS OR
            REGULATIONS. NEITHER WE, NOR ANY OF OUR AFFILIATES, LICENSORS, OR
            SUPPLIERS WARRANT THAT THE PLATFORM WILL CONTINUE TO BE PROVIDED,
            WILL FUNCTION AS DESCRIBED, CONSISTENTLY OR IN ANY PARTICULAR
            MANNER, WILL BE UNINTERRUPTED, ACCURATE, ERROR FREE, OR FREE OF
            HARMFUL COMPONENTS, OR WILL PROVIDE THE RESULTS YOU EXPECT. NEITHER
            WE, NOR ANY OF OUR AFFILIATES, LICENSORS, OR SUPPLIERS WILL BE
            RESPONSIBLE FOR (A) ANY ERRORS, INACCURACIES, OR SERVICE
            INTERRUPTIONS, INCLUDING POWER OUTAGES OR SYSTEM FAILURES; OR (B)
            ANY UNAUTHORIZED ACCESS TO OR ALTERATION OF, OR DELETION,
            DESTRUCTION, DAMAGE, OR LOSS OF, YOUR CONTENT OR ANY DATA, IMAGES,
            TEXT, OR OTHER INFORMATION OR CONTENT. WE MAY DISCONTINUE ALL OR ANY
            PART OF THE PLATFORM, AND ANY PRODUCT OR SERVICE, OR MAY CHANGE THE
            NATURE, FEATURES, FUNCTIONS, SCOPE, OR OPERATION OF THE PLATFORM, AT
            ANY TIME AND FROM TIME-TO-TIME. YOU ASSUME TOTAL RESPONSIBILITY AND
            RISK FOR YOUR USE OF THE PLATFORM, RELATED SERVICES, AND ANY LINKED
            WEBSITES. Some jurisdictions do not allow the disclaimer or
            exclusion of certain warranties. If any of the provisions of this
            Section are held to be void or unenforceable, such provisions shall
            be limited or eliminated to the minimum extent necessary and
            replaced with a valid provision that best embodies the intent of the
            Agreement. 13. LIMITATIONS OF LIABILITY EXCEPT TO THE EXTENT
            SPECIFICALLY PROVIDED OTHERWISE IN A SITE AGREEMENT, NEITHER WE, NOR
            OUR LICENSORS OR SUPPLIERS ARE RESPONSIBLE NOR LIABLE FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR
            PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION LOST PROFITS, OR
            OTHER DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THE PLATFORM,
            RELATED SERVICES, CONTENT OR INFORMATION CONTAINED IN THE PLATFORM
            OR ANY LINKED WEBSITE, ANY SERVICES PROVIDED TO OR BY USERS OF THE
            PLATFORM, ANY Toothlens USER DATA, ANY REGULATORY INFORMATION, OR
            ANY RESPONSES, MATERIALS OR INFORMATION PROVIDED, RECEIVED,
            ACCESSED, PROCESSED, UPLOADED OR DOWNLOADED ON, THROUGH, TO OR FROM
            THE PLATFORM. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE
            PLATFORM, RELATED SERVICES, CONTENT OR INFORMATION, OR LINKED
            WEBSITES IS TO STOP USING THE PLATFORM, RELATED SERVICES, CONTENT OR
            INFORMATION, OR LINKED WEBSITES. IF WE ARE FOUND TO BE LIABLE
            DESPITE THE LIMITATIONS ABOVE, IN NO EVENT SHALL Toothlens BE LIABLE
            FOR ANY DAMAGES RELATING TO THE AGREEMENT GREATER THAN ONE THOUSAND
            U.S. DOLLARS ($1,000), TO THE GREATEST EXTENT PERMISSIBLE UNDER
            APPLICABLE LAW. WE WILL NOT BE LIABLE TO YOU FOR ANY DAMAGES AT ALL
            RELATING IN ANY WAY TO OTHER USERS OF THE PLATFORM. NEITHER WE, NOR
            OUR LICENSORS OR SUPPLIERS ARE LIABLE FOR ANY HARM RESULTING FROM
            YOURS OR THIRD PARTY ACTIONS, OMISSIONS, OR CONTENT, YOUR USE OF THE
            SERVICE, OR ANY ACCESS TO OR COLLECTION, USE, TRANSMISSION OR
            DISCLOSURE OF YOUR ORIGINAL PROVIDER GROUP INFORMATION OR ANY OTHER
            INFORMATION ABOUT YOU, OUR AFFILIATES, PROVIDERS, SUBSCRIBERS, OR
            ANY OTHER THIRD PARTY. WE ASSUME NO RESPONSIBILITY FOR ANY PLATFORM
            USER’S ACTIONS, OMISSIONS, OR CONTENT, COMPLIANCE WITH THIS OR ANY
            OTHER AGREEMENTS, DUTIES TO YOU OR TO THIRD PARTIES, OR ANY
            COMPLIANCE WITH ANY LAWS, RULES AND REGULATIONS. Some jurisdictions
            do not allow the exclusion of certain liabilities. If any of the
            provisions of this Section are held to be void or unenforceable,
            such provisions shall be limited or eliminated to the minimum extent
            necessary and replaced with a valid provision that best embodies the
            intent of the Agreement. 14. THIRD PARTY TERMS, APPLICATIONS, AND
            WEBSITES Toothlens makes no representations whatsoever about any
            other software application, database, or website that You may access
            through the Platform, including any payment processor or any Widgets
            available on our Platform Sites. When You access a non-Toothlens
            software application, database, website or Widget, please understand
            that it is independent from Toothlens, and that Toothlens has no
            control over the content. In addition, a link to a nonToothlens
            website, or the availability or inclusion of a third party software
            application, database, or Widget on our Platform Sites, does not
            mean that Toothlens endorses or accepts any responsibility for the
            content, or the use of the linked website or Widget, or any products
            or services offered by that website or Widget Provider. You must
            ensure that whatever You select for Your use or download from that
            website or Widget is free of items such as viruses, worms, Trojan
            horses, and other items of a destructive nature, and that such use
            or download complies with copyright and other applicable laws. 15.
            RESOLVING DISPUTES THIS SECTION AFFECTS YOUR RIGHTS, PLEASE READ
            CAREFULLY BEFORE AGREEING TO THESE TERMS. The Agreement is governed
            by and interpreted in accordance with the laws of the State of
            Delaware without regard to its rules regarding conflicts. Exclusive
            jurisdiction and venue for actions related to the Agreement will be
            the state or federal courts located in Delaware, and the parties
            consent to the jurisdiction of such courts with respect to any such
            action. THIS INCLUDES DISPUTES WITH Toothlens, ITS AFFILIATES, AND
            EACH OF THEIR SUCCESSORS AND ASSIGNS. WE EACH WAIVE ANY RIGHT TO A
            JURY TRIAL. You acknowledge that Your breach or threatened breach of
            any of Your obligations regarding use or misuse of Participation
            Information, Toothlens User Data, or Toothlens Confidential
            Information would not be susceptible to relief by way of monetary
            damages only. Accordingly, Toothlens, in that case, may apply to
            court for applicable equitable remedies including, but not limited
            to, injunctive relief, without the need to post any security. The
            prevailing party in any legal action between us relating to the
            Agreement will be entitled to an award of reasonable attorneys’
            fees, and the court shall specifically identify the “prevailing
            party” for this purpose in the arbitration or other award. 16.
            COMMUNICATIONS We may send notifications and other communications
            relating to the Agreement to the email address associated with Your
            Platform account at that time. You may opt out of receiving
            marketing emails at any time by contacting us through your Platform
            account. You will be deemed to have received all notifications
            Toothlens communications sent to that email address, even if the
            email address associated with the applicable account is no longer
            current. IT IS YOUR RESPONSIBILITY TO KEEP THE EMAIL ADDRESSES IN
            YOUR ACCOUNTS UPTO-DATE, AND TO MONITOR YOUR EMAIL, AND TO RESPOND
            IN AN APPROPRIATE MANNER. You agree that You are able to print the
            communications for Your records. You agree that all communications
            that we send to You electronically satisfy any legal requirement
            that a communication be in writing. All communications required to
            be in paper form will be sent in paper form. For applicable
            Providers, to the extent You desire to have Your Practitioners
            invited to join the Platform via email notification from Toothlens,
            You hereby represent and warrant that You have the authority to
            provide Toothlens with the Practitioner’s email address in
            accordance with all applicable laws and regulations. 17.
            MISCELLANEOUS You and we are independent contractors, and nothing in
            the Agreement will create any partnership, joint venture, agency,
            franchise, sales representative, or employment relationship between
            You and us or our respective affiliates. You have no authority to
            make or accept any offers or make any representations on our behalf.
            You may not transfer, assign or otherwise convey its rights or
            obligations under the Agreement without the written consent of
            Toothlens, and any attempt to transfer, assign or otherwise convey
            any rights or obligations in violation of this paragraph shall be
            void. Subject to that restriction, the Agreement will be binding on,
            inure to the benefit of, and be enforceable against the parties and
            their respective successors and assigns. A failure to enforce strict
            performance of any provision of the Agreement will not constitute a
            waiver of our right to subsequently enforce such provision or any
            other provision of the Agreement. If any part of the Agreement is
            found illegal or unenforceable under applicable law, the Agreement
            will be enforced to the maximum extent permissible, and the legality
            and enforceability of the remainder of the provision and all other
            provisions of the Agreement will not be affected. The Agreement is
            for the benefit of the parties hereto, and is not entered into for
            the benefit of, and shall not be construed to confer any benefit
            upon, any other person or entity. The Agreement shall be construed
            without regard to any presumption or other rule requiring
            construction against the party causing the Agreement to be drafted.
            Any provision of the Agreement that, given its purpose,
            interpretation or context, logically should survive the expiration
            or termination of the Agreement, shall so survive. The Agreement may
            be agreed to via electronic signatures, and in more than one
            counterpart, each of which together shall form one and the same
            instrument. USE OF OUR PLATFORM AND ANY RELATED SERVICES ARE SUBJECT
            TO YOUR COMPLIANCE WITH THE TERMS SET FORTH IN ALL THE DOCUMENTS
            INCORPORATED INTO THE AGREEMENT, INCLUDING BUT NOT LIMITED TO THE
            Toothlens BAA.
            <br></br>
            <br></br>
            PLEASE READ THIS INFORMATION CAREFULLY. BY CLICKING “I AGREE” BELOW,
            OR OTHERWISE INDICATING YOUR AGREEMENT, OR BY YOUR USING OUR
            PLATFORM, YOU ARE INDICATING THAT YOU OR YOUR COMPANY AGREE TO BE
            BOUND BY THE AGREEMENT, INCLUDING THE Toothlens BAA, AND THAT YOU
            HAVE THE AUTHORITY TO BIND YOUR COMPANY. IF YOU DO NOT AGREE TO BE
            BOUND BY THE AGREEMENT, PROMPTLY EXIT THE PLATFORM.
          </div>

          <div className="m-5 d-flex w-5">
            <input
            id = "terms"
              className="checkbox-size"
              type="checkbox"
              defaultChecked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label htmlFor = "terms" className="mx-2" style={{ fontweight: "bold" }}>
              {" "}
              I agree the terms and condition
            </label>
          </div>

          <div className="d-flex w-5 justify-content-center">
            <Button
              className="form-btn mt-3"
              disabled={!checked}
              type="button"
              id="terms"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
