import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { getAppDetails } from "../../Store/appDetails";
import { Container, Row, Col } from "react-bootstrap";
import FormPage from "./formpage"
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect } from "react"
import PacmanLoader from "react-spinners/PacmanLoader";
import NavigationMenu from "../NavigationMenu";
import Navbars from "../Navbar/navbar";
import TokenExpired from "../TokenExpired";
import Loader from "../Loader";

const OfficeDetails = () => {
    const dispatch = useDispatch();
    const app = JSON.parse(localStorage.getItem("xld")).app;
    const { isLoading, data, error, fetched } = useSelector((state) => state.appDetails);
    const officeLogo = useSelector((state) => state.appLogo).officeLogo
    const officeLogoFetched = useSelector((state) => state.appLogo).fetched
    const [edit, setEdit] = useState(false)


    useEffect(() => {
        !fetched && dispatch(getAppDetails({ app }))
    }, [edit])

    const Details = () => {
        return (

            <Container className="container my-5 dental-office-details-container shadow m-auto ">
                {!isLoading && error ? <div className="d-flex justify-content-center align-items-center h-100"> <p className=""> Clinic details not found.</p> </div> :
                    <Row>
                        <Col className="my-3 office-details-logo-container d-flex align-items-center justify-content-center">
                            {officeLogo ? <img src={officeLogo} alt="dental office logo" className="company-logo-dimensions" /> : officeLogoFetched ? <p>No Logo</p> : <p>Loading...</p>}
                        </Col>
                        <Col className="d-flex flex-column justify-content-center office-details-info-container">

                            <Row>

                                <Row>
                                    <Col md={4} className="  "> Dental office name : </Col>
                                    <Col md={8} className=" text-primary text-primary"> {data.app_name} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="  "> Mobile app url : </Col>
                                    <Col md={8} className=" text-primary text-success"> {data.app_url} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> Email : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_email} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> Phone number : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_phone_number} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> Address : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_address} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> City : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_city} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> State : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_state} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> Country : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_country} </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className=" "> Zip code : </Col>
                                    <Col md={8} className=" text-primary"> {data.app_zip_code}</Col>
                                </Row>
                            </Row>
                            <Row className="align-self-start  ms-2 mt-5">
                                <button className="btn btn-primary px-3" onClick={() => { setEdit(true) }}>
                                    Update
                                    <AiOutlineEdit className="edit-icon" />

                                </button>

                            </Row>

                        </Col>
                    </Row>}

            </Container>
        )
    }

    return (
        <div className="office-details-container">
            <Navbars header={"Office Details"} />
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            <div className="container-fluid office-details-sub-container d-flex ">
                <NavigationMenu active={"office"} />
                {isLoading ? <Loader /> :
                    <>
                        {edit ? <FormPage back={() => { setEdit(false) }} appDetails={data} /> : <Details />}
                    </>
                }
            </div>


        </div>
    )

}

export default OfficeDetails