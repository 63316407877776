import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./signUp.css";
import Logo from "../../assets/ToothLensLogo.png";
import ToothScan from "../../assets/scanTeeth.svg";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import { verifyCodeService } from "../../Services/AlignerService";
import { useLocation } from "react-router-dom";
import BaaAggrement from "./baaAggrement";
import SnackFire from "../../UI/Snackbar/Snackbar";
const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [baaAggrement, setbaaAggrement] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("signupDetails"));
  const [snackbarmessage, setsnackbarmessage] = useState(
    "Code sent to your email. Please check your spam folder also!!"
  );
  const [snackbar, setsnackbar] = useState(true);
  const [type, settype] = useState("success");
  useEffect(() => {
    setsnackbarmessage(
      "Code sent to your email. Please check your spam folder also!!"
    );
    setsnackbar(true);
    settype("success");
  }, []);

  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <Container fluid>
      {
        <Formik
          validate={(values) => {
            let errors = {};
            if (!values.verification_code) {
              errors.verification_code = "Verify Code is required";
            }

            return errors;
          }}
          validationSchema={Yup.object().shape({
            verification_code: Yup.string().required("Verify Code is required"),
          })}
          initialValues={{
            verification_code: userDetails ? userDetails.verification_code : "",
          }}
          onSubmit={(values) => {
            let data = {
              ...values,
              email: location.state,
            };
            verifyCodeService(data)
              .then((response) => {
                if (response) {
                  setbaaAggrement(true);
                }
              })
              .catch((error) => {
                setsnackbarmessage("Invalid Verification Code")
                settype("error");
                setsnackbar(true)
                console.log(error.data)
                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <Row>
                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex signup_image justify-content-center align-items-center"
                  >
                    <img
                      className="image-position"
                      src={ToothScan}
                      alt="Scan"
                    />
                    <div className="dashboard-footer d-flex  my-5 py-5 align-items-end height-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className="d-flex align-items-center justify-content-center position"
                  >
                    <div className="position-absolute">
                      <img src={Logo} className="images" alt="Logo" />
                    </div>
                    <div>
                      <Col>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark field-text"
                        >
                          <span className="field-texts  mx-3">
                            {" "}
                            Signup Code
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          type="text"
                          id="verification_code"
                          className="form-input mb-3 grey-bg-color"
                          placeholder="380 Dental"
                          value={values.verification_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.verification_code &&
                          touched.verification_code && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.verification_code}
                            </div>
                          )}
                      </Col>

                      <Col className="mt-3">
                        <Button className="form-btn" type="submit">
                          <span className="btn-text">SIGNUP</span>
                        </Button>
                      </Col>
                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 mt-3 pt-3 pl-5 font-weight-500 text-dark "
                        >
                          <span className="already-text">
                            {" "}
                            Already have an account.
                          </span>{" "}
                          <span className="login-link-text">
                            <Link to="/login"> Sign in</Link>
                          </span>
                        </FormLabel>
                      </Col>
                    </div>

                    {/* </div> */}
                  </Col>
                  {baaAggrement && <BaaAggrement />}
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default SignUp;
