import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAlignerAppDetails } from '../Services/AlignerService'

const initialState = {
    isLoading: false,
    data: {},
    error: '',
    fetched: false,
}

export const getAppDetails = createAsyncThunk('appDetails/getAppDetails', async (obj) => {
    return await getAlignerAppDetails(obj)
})


const appDetailsSlice = createSlice({
    name: "appDetails",
    initialState,
    reducers: {
        clearAppDetails: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAppDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
                state.fetched = true
            })
            .addCase(getAppDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                state.fetched = false
            })

    },


})


export default appDetailsSlice.reducer
export const { clearAppDetails } = appDetailsSlice.actions
